// react
import React, { useState, useEffect, useRef } from "react";

// third-party
import { toast } from "react-toastify";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import qs from "query-string";
import ReCAPTCHA from "react-google-recaptcha";

// application
import PageHeader from "../shared/PageHeader";
import { getUsr, config, setCookie, recapta_site_key } from "../../data/custom";
import MessageAlert from "../errors/MessageAlert";
import { loadU_S_R, setPriceFilter, setBrandCheck } from "../../store/app";

// data stubs
import theme from "../../data/theme";

function AccountPageRegister({ loadU_S_R, cart, priceFilter, brandCheck, setPriceFilter, setBrandCheck, customer }) {
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "My Account Register", url: "" },
    ];
    const recaptchaRef = useRef(null);
    const { handleSubmit, register, errors, reset } = useForm();
    const [load, setLoad] = useState(false);
    const [alertMsg, setAlertMsg] = useState([]);
    const [back, setBack] = useState("");
    let history = useHistory();
    useEffect(() => {
        const to = qs.parse(window.location.search);
        if (to.return && to.return === "checkout") {
            if (cart.items.length < 1) {
                return history.push("login");
            }
            if (customer) {
                return history.push("checkout");
            }
            setBack(to.return);
        } else if (to.return && to.return === "factory") {
            if (customer) {
                return history.push("factory");
            }
            setBack(to.return);
        } else if (customer) {
            return history.push("");
        }
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
    }, [customer, brandCheck, history, priceFilter, setBrandCheck, setPriceFilter, cart.items.length]);
    const onSubmit = async (values) => {
        setLoad(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setLoad(false);
            return toast.error("No internet connection found!");
        }
        fetch(`${config.apiUrl}/auth/register`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    const usr = getUsr(data.data);
                    reset();
                    toast.success(`Hello ${data.data.name}. You are now registered!`);
                    setCookie("optc_token", data.token);
                    setCookie("optc_u_s_r", usr);
                    loadU_S_R(usr);
                } else {
                    setAlertMsg(data.error);
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("No internet connection found!");
                } else {
                    toast.error("An error occured!");
                }
                setLoad(false);
            });
    };
    let handleReturnMsg = null;
    if (back === "checkout") {
        handleReturnMsg = (
            <div className="col-12 mb-3">
                <div className="alert alert-primary alert-lg">
                    <strong>
                        Register below to proceed with your checkout if you are a new customer or,{" "}
                        <Link to="/login?return=checkout">login here</Link> if you are a registered customer
                    </strong>
                </div>
            </div>
        );
    }
    if (back === "factory") {
        handleReturnMsg = (
            <div className="col-12 mb-3">
                <div className="alert alert-primary alert-lg">
                    <strong>
                        Register below to access factory request page if you are a new customer or,{" "}
                        <Link to="/login?return=factory">login here</Link> if you are a registered customer
                    </strong>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Register — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="My Account" breadcrumb={breadcrumb} />
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
            <div className="block">
                <div className="container">
                    <div className="row">
                        {handleReturnMsg}
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title text-center">Register</h3>
                                    {alertMsg.length ? (
                                        <div className="">
                                            <MessageAlert setAlertMsg={setAlertMsg}>
                                                {alertMsg.map((alertM, index) => (
                                                    <strong key={index}>{alertM}</strong>
                                                ))}{" "}
                                            </MessageAlert>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label htmlFor="name">
                                                Name
                                                <sup className="text-danger"> * </sup>{" "}
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.name,
                                                })}
                                                id="name"
                                                name="name"
                                                ref={register({
                                                    required: "Please enter your name.",
                                                    maxLength: {
                                                        value: 100,
                                                        message: "Name can not be more than 100 characters",
                                                    },
                                                })}
                                            />
                                            {errors.name && (
                                                <div className="invalid-feedback">{errors.name.message}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-phone">
                                                Phone Number (to be used for login)
                                                <sup className="text-danger"> *</sup>
                                            </label>
                                            <input
                                                autoComplete="off"
                                                name="number"
                                                id="login-phone"
                                                type="text"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.number,
                                                })}
                                                placeholder="Phone number"
                                                ref={register({
                                                    required: "Please enter your phone number.",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Phone number is too short",
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message: "Phone number is too long",
                                                    },
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Invalid phone number",
                                                    },
                                                })}
                                            />
                                            {errors.number && (
                                                <div className="invalid-feedback">{errors.number.message}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email address</label>
                                            <input
                                                type="text"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.email,
                                                })}
                                                id="email"
                                                name="email"
                                                ref={register({
                                                    maxLength: {
                                                        value: 70,
                                                        message: "Email can not be more than 70 characters",
                                                    },
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: "Invalid email address",
                                                    },
                                                })}
                                            />
                                            {errors.email && (
                                                <div className="invalid-feedback">{errors.email.message}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="address">Address</label>
                                            <textarea
                                                type="text"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.address,
                                                })}
                                                id="address"
                                                name="address"
                                                ref={register({
                                                    maxLength: {
                                                        value: 200,
                                                        message: "Address should not be more than 200 characters",
                                                    },
                                                })}
                                            />
                                            {errors.address && (
                                                <div className="invalid-feedback">{errors.address.message}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">
                                                Create Password<sup className="text-danger"> *</sup>
                                            </label>
                                            <input
                                                autoComplete="off"
                                                name="password"
                                                id="login-password"
                                                type="password"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.password,
                                                })}
                                                placeholder="Password"
                                                ref={register({
                                                    required: "Please enter your password",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Password should not be less than 4 characters",
                                                    },
                                                })}
                                            />
                                            {errors.password && (
                                                <div className="invalid-feedback">{errors.password.message}</div>
                                            )}
                                        </div>
                                        <button
                                            disabled={load ? true : false}
                                            type="submit"
                                            className={classNames("btn btn-block btn-primary mt-3 mt-md-3 mt-lg-4", {
                                                "btn-loading": load,
                                            })}
                                        >
                                            Register
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title text-center">Login</h3>
                                    <p>If you have already registered you can login by clicking the button below.</p>
                                    <Link to={back ? `/login?return=${back}` : "/login"}>
                                        <button type="submit" className="btn btn-block btn-dark mt-2 mt-md-3 mt-lg-4">
                                            Login
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

AccountPageRegister.propTypes = {
    loadU_S_R: PropTypes.func,
};
const mapStateToProps = (state) => ({
    cart: state.cart,
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    customer: state.app.customer.name,
});
const mapDispatchToProps = {
    loadU_S_R: (customer) => loadU_S_R(customer),
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageRegister);
