// react
import React from "react";

// third-party
import classNames from "classnames";
import { Link, matchPath, Redirect, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// application
import PageHeader from "../shared/PageHeader";
import { getCookie, deleteCookie } from "../../data/custom";
import { loadU_S_R } from "../../store/app";

// pages
// import AccountPageAddresses from "./AccountPageAddresses";
import AccountPageDashboard from "./AccountPageDashboard";
// import AccountPageEditAddress from "./AccountPageEditAddress";
import AccountPageOrders from "./AccountPageOrders";
import AccountPagePassword from "./AccountPagePassword";
import AccountPageProfile from "./AccountPageProfile";
import AccountPageFactory from "./AccountPageFactory";

const AccountLayout = ({ match, location, customer, loadU_S_R }) => {
    const cookiesN = getCookie("optc_u_s_r");
    if (!customer && !cookiesN) {
        return <Redirect to="/login" />;
    }
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "My Account", url: "" },
    ];
    const logOut = (e) => {
        e.preventDefault();
        deleteCookie("optc_u_s_r");
        deleteCookie("optc_token");
        loadU_S_R("");
        toast.dark("You have logged out!");
    };
    const links = [
        { title: "Dashboard", url: "dashboard" },
        { title: "Edit Profile", url: "profile" },
        { title: "Order History", url: "orders" },
        { title: "Factory Request History", url: "factory" },
        { title: "Change Password", url: "change-password" },
        { title: "Logout", url: "login" },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames("account-nav__item", {
            "account-nav__item--active": isActive,
        });

        return (
            <li key={link.url} className={classes}>
                <Link to={url} onClick={link.title === "Logout" ? (e) => logOut(e) : null}>
                    {link.title}
                </Link>
            </li>
        );
    });

    return (
        <React.Fragment>
            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title">Navigation</h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`} component={AccountPageDashboard} />
                                <Route exact path={`${match.path}/profile`} component={AccountPageProfile} />
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                <Route exact path={`${match.path}/factory`} component={AccountPageFactory} />
                                {/* <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} />
                                <Route
                                    exact
                                    path={`${match.path}/addresses/:addressId`}
                                    component={AccountPageEditAddress}
                                /> */}
                                <Route exact path={`${match.path}/change-password`} component={AccountPagePassword} />
                                <Route>
                                    <Redirect to="" />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({
    customer: state.app.customer.name,
});
const mapDispatchToProps = {
    loadU_S_R: (customer) => loadU_S_R(customer),
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountLayout);
