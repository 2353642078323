// react
import React, { useEffect } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import Indicator from "./Indicator";
import { Cart20Svg, Cross10Svg } from "../../svg";
import { cartRemoveItem, confirmItemSaved } from "../../store/cart";

import { config } from "../../data/custom";

function IndicatorCart(props) {
    const { cart, cartRemoveItem, confirmItemSaved, reload } = props;
    useEffect(() => {
        let cartSaved = localStorage.getItem("cartItems");
        if (cartSaved && typeof cartSaved === "string") {
            cartSaved = JSON.parse(cartSaved);
            if (cartSaved.length && !cart.items.length) {
                if (Array.isArray(cartSaved)) {
                    fetch(`${config.apiUrl}/onlinesales/confirmcart`, {
                        method: "POST",
                        crossDomain: true,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            cart: cartSaved,
                        }),
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.success === true) {
                                confirmItemSaved(data.data.cart);
                            }
                        })
                        .catch((error) => {});
                }
            }
        }
        // eslint-disable-next-line
    }, [reload]);
    let dropdown;
    // let totals;

    // if (cart.extraLines.length > 0) {
    //     const extraLines = cart.extraLines.map((extraLine, index) => (
    //         <tr key={index}>
    //             <th>{extraLine.title}</th>
    //             <td>
    //                 <Currency value={cart.total - cart.subtotal} />
    //             </td>
    //         </tr>
    //     ));

    //     totals = (
    //         <React.Fragment>
    //             <tr>
    //                 <th>Subtotal</th>
    //                 <td>
    //                     <Currency value={cart.subtotal} />
    //                 </td>
    //             </tr>
    //             {extraLines}
    //         </React.Fragment>
    //     );
    // }

    const items = cart.items.map((item) => {
        //let options;
        //let image;
        // if (item.options) {
        //     options = (
        //         <ul className="dropcart__product-options">
        //             {item.options.map((option, index) => (
        //                 <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
        //             ))}
        //         </ul>
        //     );
        // }
        const image = (
            <div className="product-image dropcart__product-image">
                <div className="product-image__body">
                    <img
                        className="product-image__img"
                        src={
                            item.image
                                ? `${config.apiImageUrl}/small/${item.image}`
                                : `${config.apiImageUrl}/small/images/no-image.png`
                        }
                        alt=""
                    />
                </div>
            </div>
        );

        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.pvid)}
                render={({ run, loading }) => {
                    const classes = classNames("dropcart__product-remove btn btn-light btn-sm btn-svg-icon", {
                        "btn-loading": loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.pvid} className="dropcart__product">
                {image}
                <div className="dropcart__product-info">
                    <div className="dropcart__product-name">
                        {item.name}
                        {item.type ? (
                            <small>
                                {" "}
                                ({item.type}: {item.varname})
                            </small>
                        ) : (
                            ""
                        )}
                    </div>
                    {/* {options} */}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.count}</span>
                        {" × "}
                        <span className="dropcart__product-price">
                            <Currency value={item.price} />
                        </span>
                    </div>
                </div>
                {removeButton}
            </div>
        );
    });

    if (cart.quantity) {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__products-list">{items}</div>

                <div className="dropcart__totals">
                    <table>
                        <tbody>
                            {/* {totals} */}
                            <tr>
                                <th>Sub Total</th>
                                <td>
                                    <Currency value={cart.subtotal} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="dropcart__buttons">
                    <Link
                        style={{ fontSize: "0.9rem", height: "calc(2.2rem + 2px)" }}
                        className="btn btn-secondary"
                        to="/cart"
                    >
                        View Cart
                    </Link>
                    <Link
                        style={{ fontSize: "0.9rem", height: "calc(2.2rem + 2px)" }}
                        className="btn btn-primary"
                        to="/checkout"
                    >
                        Payment Checkout
                    </Link>
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">Your shopping cart is empty!</div>
            </div>
        );
    }

    return <Indicator url="/cart" dropdown={dropdown} value={cart.quantity} icon={<Cart20Svg />} />;
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    reload: state.app.reload,
});

const mapDispatchToProps = {
    cartRemoveItem,
    confirmItemSaved,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
