import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import states from "../../data/states";

const FactoryModal = ({ user, show, setShow, load, requestDeliveryMode }) => {
    const { handleSubmit, register, errors, reset } = useForm();
    useEffect(() => {
        reset();
    }, [reset]);
    const handleClose = () => setShow(false);
    const onSubmit = (values) => {
        requestDeliveryMode(values, "shipment");
    };
    return (
        <div>
            <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
                <Modal.Header closeButton={load ? false : true}>
                    <Modal.Title>Shipping Delivery Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-row">
                        <div className="col-sm-12 mt-2 h5">Please enter your delivery details below.</div>
                    </div>
                    <div className="form-row">
                        <div className="col-sm-6">
                            <div className="form-group mt-2 mb-2">
                                <label htmlFor="name">
                                    Delivery contact Name<sup className="text-danger"> *</sup>
                                </label>
                                <input
                                    id="name"
                                    type="text"
                                    className="form-control"
                                    name="name"
                                    defaultValue={user}
                                    ref={register({
                                        required: "Delivery name is required",
                                        maxLength: {
                                            value: 100,
                                            message: "Name can not be more than 100 characters",
                                        },
                                    })}
                                />
                                {errors.name && <p className="text-danger">{errors.name.message}</p>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group mt-2 mb-2">
                                <label htmlFor="number">
                                    Delivery contact Number<sup className="text-danger"> *</sup>
                                </label>
                                <input
                                    id="number"
                                    type="text"
                                    className="form-control"
                                    placeholder="e.g 08012345678"
                                    name="number"
                                    ref={register({
                                        required: "Phone number for delivery is required",
                                        minLength: {
                                            value: 4,
                                            message: "Phone number is too short",
                                        },
                                        maxLength: {
                                            value: 20,
                                            message: "Phone number is too long",
                                        },
                                        pattern: {
                                            value: /^[0-9]*$/,
                                            message: "Invalid phone number",
                                        },
                                    })}
                                />
                                {errors.number && <p className="text-danger">{errors.number.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-sm-12">
                            <div className="form-group mb-2">
                                <label htmlFor="address">
                                    Delivery address<sup className="text-danger"> *</sup>
                                </label>
                                <textarea
                                    id="address"
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    ref={register({
                                        required: "Delivery address is required",
                                        maxLength: {
                                            value: 100,
                                            message: "Delivery address should not be more than 100 characters",
                                        },
                                    })}
                                />
                                {errors.address && <p className="text-danger">{errors.address.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-sm-6">
                            <div className="form-group mb-2">
                                <label htmlFor="city">
                                    Delivery City/Town<sup className="text-danger"> *</sup>
                                </label>
                                <input
                                    id="city"
                                    type="text"
                                    className="form-control"
                                    name="city"
                                    ref={register({
                                        required: "Delivery city or town is required",
                                    })}
                                />
                                {errors.city && <p className="text-danger">{errors.city.message}</p>}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-group mb-2">
                                <label htmlFor="state">
                                    State<sup className="text-danger"> *</sup>
                                </label>
                                <select
                                    id="state"
                                    className="form-control"
                                    name="state"
                                    ref={register({
                                        required: "Please select a state",
                                    })}
                                >
                                    <option value="">-- Choose A State --</option>
                                    {states.map((st, index) => (
                                        <option key={index} value={st}>
                                            {st}
                                        </option>
                                    ))}
                                </select>
                                {errors.states && <p className="text-danger">{errors.states.message}</p>}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={load ? true : false} className="btn btn-sm btn-dark" onClick={handleClose}>
                        Cancel
                    </button>
                    <button
                        className={classNames("btn btn-sm btn-primary", {
                            "btn-loading": load,
                        })}
                        disabled={load ? true : false}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Send Request
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FactoryModal;
