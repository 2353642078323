// react
import React, { useState, useEffect, useRef } from "react";

// third-party
import { toast } from "react-toastify";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReCAPTCHA from "react-google-recaptcha";

// application
import Indicator from "./Indicator";
import { Person20Svg, Check9x7Svg } from "../../svg";
import { config, getUsr, getCookie, setCookie, deleteCookie, recapta_site_key } from "../../data/custom";
import MessageAlert from "../errors/MessageAlert";
import { loadU_S_R } from "../../store/app";

function IndicatorAccount({ customer, loadU_S_R }) {
    const logRef = useRef(null);
    const recaptchaRef = useRef(null);
    const { handleSubmit, register, errors, reset } = useForm();
    const [load, setLoad] = useState(false);
    const [alertMsg, setAlertMsg] = useState([]);
    useEffect(() => {
        const Customer = getCookie("optc_u_s_r");
        const Token = getCookie("optc_token");
        if (Customer && Token) {
            loadU_S_R(JSON.parse(Customer));
        } else {
            deleteCookie("optc_u_s_r");
            deleteCookie("optc_token");
        }
    }, [loadU_S_R]);
    const onSubmit = async (values) => {
        setLoad(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setLoad(false);
            return toast.error("No internet connection found!");
        }
        fetch(`${config.apiUrl}/auth/login`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    const usr = getUsr(data.data);
                    let lref = null;
                    if (logRef.current.checked) {
                        lref = 30;
                    }
                    setCookie("optc_token", data.token, lref);
                    setCookie("optc_u_s_r", usr, lref);
                    loadU_S_R(usr);
                    toast.success(`Hello ${data.data.name}. You are logged in${lref ? " for 30days" : ""}!`);
                } else {
                    setAlertMsg(data.error);
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("No internet connection found!");
                } else {
                    toast.error("An error occured!");
                }
                setLoad(false);
            });
    };
    const clearForm = () => {
        setAlertMsg([]);
        reset();
    };
    const logOut = (e) => {
        e.preventDefault();
        setAlertMsg([]);
        deleteCookie("optc_u_s_r");
        deleteCookie("optc_token");
        loadU_S_R("");
        toast.dark("You have logged out!");
    };
    const droplogin = (
        <div className="account-menu">
            <form className="account-menu__form" onSubmit={handleSubmit(onSubmit)}>
                <div className="account-menu__form-title pb-3">New Customer?</div>
                <div className="account-menu__form-link">
                    <Link to="/register">
                        <button type="button" className="btn btn-outline-secondary btn-sm">
                            Click here to register
                        </button>
                    </Link>
                </div>
                <div className="account-menu__form-title pt-3 pb-3">Log In to Your Account</div>
                {alertMsg.length ? (
                    <MessageAlert setAlertMsg={setAlertMsg}>
                        {alertMsg.map((alertM, index) => (
                            <strong key={index}>{alertM}</strong>
                        ))}{" "}
                    </MessageAlert>
                ) : (
                    ""
                )}
                <div className="form-group">
                    <label htmlFor="number" className="sr-only">
                        Phone Number
                    </label>
                    <input
                        autoComplete="off"
                        id="number"
                        type="text"
                        className={`form-control form-control-sm${errors.number ? " is-invalid" : ""}`}
                        placeholder="Phone number"
                        name="number"
                        ref={register({
                            required: "Please enter your phone number.",
                            minLength: {
                                value: 4,
                                message: "Phone number is too short",
                            },
                            maxLength: {
                                value: 20,
                                message: "Phone number is too long",
                            },
                            pattern: {
                                value: /^[0-9]*$/,
                                message: "Invalid phone number.",
                            },
                        })}
                    />
                    <div className="invalid-feedback">{errors.number && errors.number.message}</div>
                </div>
                <div className="form-group">
                    <label htmlFor="header-signin-password" className="sr-only">
                        Password
                    </label>
                    <div className="account-menu__form-forgot">
                        <input
                            autoComplete="off"
                            id="header-signin-password"
                            type="password"
                            className={`form-control form-control-sm${errors.password ? " is-invalid" : ""}`}
                            placeholder="Password"
                            name="password"
                            ref={register({
                                required: "Please enter your password.",
                                minLength: {
                                    value: 4,
                                    message: "Your Password is not less than 4 characters",
                                },
                            })}
                        />
                        <div className="invalid-feedback">{errors.password && errors.password.message}</div>
                        <small className="form-text text-muted">
                            <Link to="/forget-password">Forget Password?</Link>
                        </small>
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-check">
                        <span className="form-check-input input-check">
                            <span className="input-check__body">
                                <input
                                    ref={logRef}
                                    id="login-remember"
                                    type="checkbox"
                                    className="input-check__input"
                                />
                                <span className="input-check__box" />
                                <Check9x7Svg className="input-check__icon" />
                            </span>
                        </span>
                        <label className="form-check-label" htmlFor="login-remember">
                            Keep me logged-in for 30 days
                        </label>
                    </div>
                </div>
                <div className="form-group account-menu__form-button">
                    <button
                        disabled={load ? true : false}
                        type="submit"
                        className={classNames("btn btn-primary btn-sm mr-4", {
                            "btn-loading": load,
                        })}
                    >
                        Login
                    </button>
                    <button onClick={clearForm} type="button" className="btn btn-dark btn-sm">
                        Clear
                    </button>
                </div>
            </form>
        </div>
    );
    const droplogged = (
        <div className="account-menu">
            <div className="account-menu__divider" />
            <Link to="/account/dashboard" className="account-menu__user">
                <div className="account-menu__user-info">
                    <div className="account-menu__user-name text-capitalize">{customer}</div>
                </div>
            </Link>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li>
                    <Link to="/account/profile">Edit Profile</Link>
                </li>
                <li>
                    <Link to="/account/orders">Order History</Link>
                </li>
                <li>
                    <Link to="/account/factory">Factory request History</Link>
                </li>
                <li>
                    <Link to="/account/change-password">Change Password</Link>
                </li>
            </ul>
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li>
                    <Link to="/" onClick={(e) => logOut(e)}>
                        Logout
                    </Link>
                </li>
            </ul>
        </div>
    );
    let dropdown;
    if (customer) {
        dropdown = droplogged;
    } else {
        dropdown = droplogin;
    }

    return (
        <>
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
            <Indicator url="/account" dropdown={dropdown} reset={reset} icon={<Person20Svg />} />
        </>
    );
}
IndicatorAccount.propTypes = {
    customer: PropTypes.string,
    loadU_S_R: PropTypes.func,
};

IndicatorAccount.defaultProps = {
    customer: "",
};
const mapStateToProps = (state) => ({
    customer: state.app.customer.name,
});

const mapDispatchToProps = {
    loadU_S_R: (customer) => loadU_S_R(customer),
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorAccount);
