import React from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";

const DeleteAccountModal = ({ show, setShow, load2, deleteUserAccount }) => {
    const handleClose = () => setShow(false);
    return (
        <Modal show={show} onHide={handleClose} centered size="lg">
            <Modal.Body>
                Deleting your account means that all of your data and information of this account will be permanently
                removed. This action is irreversible!
            </Modal.Body>
            <Modal.Footer>
                <button disabled={load2 ? true : false} className="btn btn-sm btn-dark" onClick={handleClose}>
                    Cancel
                </button>
                <button
                    className={classNames("btn btn-sm btn-danger", {
                        "btn-loading": load2,
                    })}
                    disabled={load2 ? true : false}
                    onClick={() => deleteUserAccount()}
                >
                    Delete
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteAccountModal;
