// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// application
import NavPanel from "./NavPanel";
import Search from "./Search";
import { LogoSvg } from "../../svg";

const Header = ({ layout, info }) => {
    let bannerSection;
    if (layout === "default") {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/">
                        <LogoSvg />
                    </Link>
                </div>
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                <div className="site-header__phone">
                    <div className="site-header__phone-title">Customer Support</div>
                    <div className="site-header__phone-number">
                        {info && info.webphone ? info.webphone : "08**********"}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div>
        </div>
    );
};

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};
const mapStateToProps = (state) => ({
    info: state.menu.info,
});
export default connect(mapStateToProps)(Header);
