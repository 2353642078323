export default [
    {
        title: "Home",
        url: "/",
    },
    {
        title: "Factory Request",
        url: "/factory",
        // submenu: {
        //     type: "menu",
        //     menu: [
        //         { title: "Enter Factory", url: "/factory" },
        //         { title: "Send Request", url: "/factory" },
        //     ],
        // },
    },
    {
        title: "Information",
        url: "/about-us",
        submenu: {
            type: "menu",
            menu: [
                { title: "About Us", url: "/about-us" },
                { title: "Terms And Conditions", url: "/terms" },
                { title: "Privacy Policy", url: "/privacy-policy" },
            ],
        },
    },
    // {
    //     title: "Pages",
    //     url: "/about-us",
    //     submenu: {
    //         type: "menu",
    //         menu: [
    //             { title: "About Us", url: "/about-us" },
    //             { title: "Contact Us", url: "/site/contact-us" },
    //             { title: "Contact Us Alt", url: "/site/contact-us-alt" },
    //             { title: "404", url: "/site/not-found" },
    //             { title: "Terms And Conditions", url: "/terms" },
    //             { title: "FAQ", url: "/site/faq" },
    //             { title: "Components", url: "/site/components" },
    //             { title: "Typography", url: "/site/typography" },
    //             { title: "Compare", url: "/shop/compare" },
    //             { title: "Track Order", url: "/shop/track-order" },
    //         ],
    //     },
    // },
    // {
    //     title: "Blog",
    //     url: "/blog/category-classic",
    //     submenu: {
    //         type: "menu",
    //         menu: [
    //             { title: "Blog Classic", url: "/blog/category-classic" },
    //             { title: "Blog Grid", url: "/blog/category-grid" },
    //             { title: "Blog List", url: "/blog/category-list" },
    //             { title: "Blog Left Sidebar", url: "/blog/category-left-sidebar" },
    //             { title: "Post Page", url: "/blog/post-classic" },
    //             { title: "Post Without Sidebar", url: "/blog/post-full" },
    //         ],
    //     },
    // },
];
