// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from "react-toastify";

// application
import AppLink from "../shared/AppLink";
import Collapse from "../shared/Collapse";
import { ArrowRoundedDown12x7Svg } from "../../svg";
import { deleteCookie } from "../../data/custom";
import { loadU_S_R } from "../../store/app";

function MobileLinks({ links, level, onItemClick, loadU_S_R }) {
    const handleItemClick = (e, to) => {
        e.preventDefault();
        if (to) {
            onItemClick(e, to);
        }
    };
    const logOut = (e) => {
        e.preventDefault();
        deleteCookie("optc_u_s_r");
        deleteCookie("optc_token");
        loadU_S_R("");
        toast.dark("You have logged out!");
    };
    const uselevel = (slug, id) => {
        if (level === 0) {
            return `/products/${slug}/${id}-0-0`;
        } else if (level === 1) {
            return `/products/${slug}/0-${id}-0`;
        } else if (level === 2) {
            return `/products/${slug}/0-0-${id}`;
        }
    };
    const linksList = links.map((link, index) => {
        let item;

        if (link.type === "link" || link.type === "button" || link.type === "logout") {
            item = (
                <Collapse
                    toggleClass="mobile-links__item--open"
                    render={({ toggle, setItemRef, setContentRef }) => {
                        let arrow;
                        let subLinks;
                        let linkOrButton;

                        if (link.children && link.children.length > 0) {
                            arrow = (
                                <button className="mobile-links__item-toggle" type="button" onClick={toggle}>
                                    <ArrowRoundedDown12x7Svg className="mobile-links__item-arrow" />
                                </button>
                            );

                            subLinks = (
                                <div className="mobile-links__item-sub-links" ref={setContentRef}>
                                    <MobileLinks
                                        links={link.children}
                                        level={level + 1}
                                        onItemClick={onItemClick}
                                        loadU_S_R={loadU_S_R}
                                    />
                                </div>
                            );
                        }

                        if (link.type === "link") {
                            linkOrButton = (
                                <AppLink
                                    to="/"
                                    className={`mobile-links__item-link${
                                        level === 0 ? " text-uppercase" : " text-capitalize"
                                    }`}
                                    onClick={(e) => handleItemClick(e, uselevel(link.slug, link.id))}
                                >
                                    {link.label}
                                </AppLink>
                            );
                        } else if (link.type === "button") {
                            linkOrButton = (
                                <AppLink
                                    to="/"
                                    className={`mobile-links__item-link${
                                        level === 0 ? " text-uppercase" : " text-capitalize"
                                    }`}
                                    onClick={(e) => handleItemClick(e, link.url)}
                                >
                                    {link.label}
                                </AppLink>
                            );
                        } else {
                            linkOrButton = (
                                <AppLink
                                    to="/"
                                    className={`mobile-links__item-link${
                                        level === 0 ? " text-uppercase" : " text-capitalize"
                                    }`}
                                    onClick={(e) => logOut(e)}
                                >
                                    {link.label}
                                </AppLink>
                            );
                        }

                        return (
                            <div className="mobile-links__item" ref={setItemRef}>
                                <div className="mobile-links__item-title">
                                    {linkOrButton}
                                    {arrow}
                                </div>
                                {subLinks}
                            </div>
                        );
                    }}
                />
            );
        } else if (link.type === "divider") {
            item = <div className="mobile-links__divider" />;
        }

        return <li key={index}>{item}</li>;
    });

    return <ul className={`mobile-links mobile-links--level--${level}`}>{linksList}</ul>;
}

MobileLinks.propTypes = {
    links: PropTypes.array,
    level: PropTypes.number,
};

MobileLinks.defaultProps = {
    links: [],
    level: 0,
};
const mapStateToProps = (state) => ({
    customer: state.app.customer.name,
});

const mapDispatchToProps = {
    loadU_S_R: (customer) => loadU_S_R(customer),
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileLinks);
