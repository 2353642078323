import { LOAD_MENU, SOCIALS, INFO, HIDETOPALERT, CLOSETOPALERT } from "./menuActionTypes";

/**
 * @param {array} menuDesktop
 * @param {array} menuMobile
 */
function firstChild(data) {
    if (!data || !data.length) {
        return [];
    }

    const childOne = data.map((data) => {
        return {
            id: data._id,
            slug: data.slug,
            type: "link",
            label: data.titlename,
            url: "",
            children: secondChild(data.submenus),
        };
    });
    return childOne;
}

function secondChild(data) {
    if (!data || !data.length) {
        return [];
    }

    const childTwo = data.map((data) => {
        return {
            id: data._id,
            slug: data.slug,
            type: "link",
            label: data.submenuname,
            url: "",
        };
    });
    return childTwo;
}

function mobileConverter(data) {
    if (!data || !data.length) {
        return [];
    }

    const newMenu = data.map((data) => {
        return {
            id: data._id,
            slug: data.slug,
            type: "link",
            label: data.categoryname,
            url: "",
            children: firstChild(data.titles),
        };
    });
    return newMenu;
}

const initialState = {
    menuDesktop: [],
    menuMobile: [],
    info: {},
    year: "",
    socials: [],
    hideTopAlert: false,
    closeTopAlert: false,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_MENU:
            return {
                ...state,
                menuDesktop: action.payload,
                menuMobile: mobileConverter(action.payload),
            };
        case INFO:
            return {
                ...state,
                info: action.payload.data[0],
                year: action.payload.year,
            };
        case SOCIALS:
            return {
                ...state,
                socials: action.payload,
            };
        case HIDETOPALERT:
            return {
                ...state,
                hideTopAlert: action.payload,
            };
        case CLOSETOPALERT:
            return {
                ...state,
                closeTopAlert: action.payload,
            };

        default:
            return state;
    }
}
