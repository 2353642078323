// react
import React, { useCallback, useRef } from "react";

// third-party
import { connect } from "react-redux";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

// application
import { Cross20Svg, Search20Svg } from "../../svg";
import { setSearch } from "../../store/app";

const Search = ({ context, className, onClose, setSearch }) => {
    let history = useHistory();
    const searchRef = useRef(null);
    //const [query, setQuery] = useState("");
    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    // const handleChangeQuery = (event) => {
    //     const query = event.target.value;
    //     setQuery(query);
    // };

    const rootClasses = classNames(`search search--location--${context}`, className, {
        "search--suggestions-open": false,
    });
    const searchNow = () => {
        if (searchRef.current.value) {
            setSearch(searchRef.current.value);
            searchRef.current.value = "";
            history.push("/products/search");
        }
    };
    const triggerSearch = (e) => {
        if (e.keyCode === 13) {
            searchNow();
        }
    };
    const closeButton =
        context !== "mobile-header" ? (
            ""
        ) : (
            <button className="search__button search__button--type--close" type="button" onClick={close}>
                <Cross20Svg />
            </button>
        );

    return (
        <div className={rootClasses}>
            <div className="search__body">
                <form className="search__form" onSubmit={(e) => e.preventDefault()}>
                    <input
                        ref={searchRef}
                        //onChange={handleChangeQuery}
                        //value={query}
                        className="search__input"
                        name="search"
                        placeholder="Search for products"
                        aria-label="Site search"
                        type="text"
                        autoComplete="off"
                        onKeyUp={triggerSearch}
                    />
                    <button onClick={searchNow} className="search__button search__button--type--submit" type="button">
                        <Search20Svg />
                    </button>
                    {closeButton}
                    <div className="search__border" />
                </form>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    setSearch: (value) => setSearch(value),
};

export default connect(null, mapDispatchToProps)(Search);
