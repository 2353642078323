// react
import React, { useState, useEffect, useRef } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
// data stubs
import theme from "../../data/theme";
import { config, getCookie, recapta_site_key } from "../../data/custom";
import ErrorLoading from "../errors/ErrorLoading";
import MessageAlert from "../errors/MessageAlert";
import { setPriceFilter, setBrandCheck } from "../../store/app";

const AccountPagePassword = ({ priceFilter, brandCheck, setPriceFilter, setBrandCheck, reload }) => {
    const recaptchaRef = useRef(null);
    const [load, setLoad] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [errMsg, seterrMsg] = useState([]);
    const [alertMsg, setAlertMsg] = useState([]);
    const { handleSubmit, register, errors, reset, watch } = useForm();
    const newPassword = useRef({});
    newPassword.current = watch("newPassword", "");
    useEffect(() => {
        setIsLoading(true);
        seterrMsg([]);
        setAlertMsg([]);
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        setIsLoading(false);
    }, [reload, priceFilter, brandCheck, setPriceFilter, setBrandCheck]);

    const onSubmit = async (values) => {
        setAlertMsg([]);
        setLoad(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setLoad(false);
            return toast.error("No internet connection found!");
        }
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/auth/updatepassword`, {
            method: "PUT",
            crossDomain: true,
            headers: {
                authorization: `Bearer ${JSON.parse(cookies)}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
                recaptchaValue: values.recaptchaValue,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    reset();
                    toast.success("Your password has been changed successfully!");
                } else {
                    setAlertMsg(data.error);
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["Please check your internet connection and reload the page"]);
                } else {
                    seterrMsg(["An error occured!"]);
                }
                setLoad(false);
                setIsLoading(false);
            });
    };
    let content;
    if (errMsg && errMsg.length) {
        content = (
            <ErrorLoading
                title={"You can not access change password!"}
                errMsg={errMsg}
                reloadNow={true}
                isLoading={isLoading}
                removeBorder={true}
            />
        );
    } else {
        content = (
            <>
                <div className="card-header">
                    <h5>Change Password</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-7 col-xl-6">
                            {alertMsg.length ? (
                                <div className="">
                                    <MessageAlert setAlertMsg={setAlertMsg}>
                                        {alertMsg.map((alertM, index) => (
                                            <strong key={index}>{alertM}</strong>
                                        ))}{" "}
                                    </MessageAlert>
                                </div>
                            ) : (
                                ""
                            )}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label htmlFor="currentPassword">Current Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="currentPassword"
                                        placeholder="Current Password"
                                        name="currentPassword"
                                        ref={register({
                                            required: "Please enter your current password",
                                            minLength: {
                                                value: 4,
                                                message: "Your current password is not less than 4 characters",
                                            },
                                        })}
                                    />
                                    {errors.currentPassword && (
                                        <p className="text-danger">{errors.currentPassword.message}</p>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="newPassword">New Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="newPassword"
                                        placeholder="New Password"
                                        name="newPassword"
                                        ref={register({
                                            required: "Please enter a new password",
                                            minLength: {
                                                value: 4,
                                                message: "Your new password should not be less than 4 characters",
                                            },
                                        })}
                                    />

                                    {errors.newPassword && <p className="text-danger">{errors.newPassword.message}</p>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirmNewPassword">Reenter New Password</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirmNewPassword"
                                        placeholder="Reenter New Password"
                                        name="confirmNewPassword"
                                        ref={register({
                                            validate: (value) =>
                                                value === newPassword.current || "Password must match new password",
                                        })}
                                    />
                                    {errors.confirmNewPassword && (
                                        <p className="text-danger">{errors.confirmNewPassword.message}</p>
                                    )}
                                </div>

                                <div className="form-group mt-5 mb-0">
                                    <button
                                        type="submit"
                                        disabled={load ? true : false}
                                        className={classNames("btn btn-primary", {
                                            "btn-loading": load,
                                        })}
                                    >
                                        Change
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return (
        <div className="card">
            <Helmet>
                <title>{`Change Password — ${theme.name}`}</title>
            </Helmet>

            {content}
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPagePassword);
