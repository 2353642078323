// react
import React, { useRef, useState, useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";

// application
import PageHeader from "../shared/PageHeader";
import { getUsr, setCookie, config, recapta_site_key } from "../../data/custom";
import { loadU_S_R, setPriceFilter, setBrandCheck } from "../../store/app";
import MessageAlert from "../errors/MessageAlert";
import ErrorLoading from "../errors/ErrorLoading";

// data stubs
import theme from "../../data/theme";
import BlockLoader from "../blocks/BlockLoader";

function AccountResetPassword({ loadU_S_R, priceFilter, brandCheck, setPriceFilter, setBrandCheck, customer, reload }) {
    const { handleSubmit, register, errors, reset, watch } = useForm();
    const password = useRef({});
    password.current = watch("password", "");
    const [alertMsg, setAlertMsg] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errMsg, seterrMsg] = useState([]);
    const [load, setLoad] = useState(false);
    const history = useHistory();
    const recaptchaRef = useRef(null);
    const { auth } = useParams();
    useEffect(() => {
        if (customer) {
            return history.push("");
        }
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        seterrMsg([]);
        setIsLoading(true);
        fetch(`${config.apiUrl}/auth/resetpassword/${auth}`, {
            method: "GET",
            crossDomain: true,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    setIsLoading(false);
                } else {
                    seterrMsg(data.error);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["Please check your internet connection and reload the page"]);
                } else {
                    seterrMsg(["An error occured!"]);
                }
                setIsLoading(false);
            });
    }, [customer, brandCheck, priceFilter, setBrandCheck, setPriceFilter, auth, reload, history]);
    if (isLoading) {
        return <BlockLoader />;
    }
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Reset Password", url: "" },
    ];
    const onSubmit = async (values) => {
        setLoad(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setLoad(false);
            return toast.error("No internet connection found!");
        }
        fetch(`${config.apiUrl}/auth/resetpassword/${auth}`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                password: values.password,
                recaptchaValue: values.recaptchaValue,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    const usr = getUsr(data.data);
                    reset();
                    toast.success(`Your password has been reset and you're logged in!`);
                    setCookie("optc_token", data.token);
                    setCookie("optc_u_s_r", usr);
                    loadU_S_R(usr);
                } else {
                    setAlertMsg(data.error);
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("No internet connection found!");
                } else {
                    toast.error("An error occured!");
                }
                setLoad(false);
            });
    };
    let content;
    if (errMsg && errMsg.length) {
        content = (
            <ErrorLoading
                title={"This order can not be shown!"}
                errMsg={errMsg}
                reloadNow={true}
                isLoading={isLoading}
                //removeBorder={true}
            />
        );
    } else {
        content = (
            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <div className="card-title text-center">
                                        <h1 className="pt-lg-0 pt-2">Reset Password</h1>
                                    </div>
                                    <p className="mb-4 pt-2">
                                        You can now create a new password below of atleast 4 characters.
                                    </p>
                                    {alertMsg.length ? (
                                        <div className="">
                                            <MessageAlert setAlertMsg={setAlertMsg}>
                                                {alertMsg.map((alertM, index) => (
                                                    <strong key={index}>{alertM}</strong>
                                                ))}{" "}
                                            </MessageAlert>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label htmlFor="login-password">
                                                Create New Password<sup className="text-danger"> *</sup>
                                            </label>
                                            <input
                                                autoComplete="off"
                                                name="password"
                                                id="login-password"
                                                type="password"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.password,
                                                })}
                                                placeholder="Password"
                                                ref={register({
                                                    required: "Please enter your new password",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Password should not be less than 4 characters",
                                                    },
                                                })}
                                            />
                                            {errors.password && (
                                                <div className="invalid-feedback">{errors.password.message}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="confirm_password">
                                                Confirm New Password<sup className="text-danger"> *</sup>
                                            </label>
                                            <input
                                                autoComplete="off"
                                                name="confirm_password"
                                                id="confirm_password"
                                                type="password"
                                                className={classNames("form-control", {
                                                    "is-invalid": !errors.password && errors.confirm_password,
                                                })}
                                                placeholder="Confirm Password"
                                                ref={register({
                                                    required: "Please confirm password",
                                                    validate: (value) =>
                                                        value === password.current || "The passwords do not match",
                                                })}
                                            />
                                            {!errors.password && errors.confirm_password && (
                                                <div className="invalid-feedback">
                                                    {errors.confirm_password.message}
                                                </div>
                                            )}
                                        </div>
                                        <div className="pt-3">
                                            <button
                                                type="submit"
                                                className={classNames("btn btn-primary btn-lg btn-block", {
                                                    "btn-loading": load,
                                                })}
                                            >
                                                Reset Password
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title text-center">Login</h3>
                                    <p>If you have already registered you can login by clicking the button below.</p>
                                    <Link to="/login">
                                        <button type="submit" className="btn btn-block btn-dark mt-2 mt-md-3 mt-lg-4">
                                            Login
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Reset Password — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
            {content}
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    reload: state.app.reload,
    customer: state.app.customer.name,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
    loadU_S_R: (customer) => loadU_S_R(customer),
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountResetPassword);
