// react
import React, { useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import parse from "html-react-parser";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
//import Rating from "./Rating";
import { cartAddItem } from "../../store/cart";
import { Wishlist16Svg } from "../../svg";
import { wishlistAddItem } from "../../store/wishlist";

import { config } from "../../data/custom";

function Product(props) {
    const { product, addProduct, setaddProduct, type, setType, layout, wishlistAddItem, cartAddItem } = props;
    const [quantity, setQuantity] = useState(1);
    let imageArray = [];
    let prices;
    let inStock = false;
    if (product && product.productvariations) {
        const finnd = product.productvariations.find((pv) => {
            return pv.quantity.find((qu) => {
                return qu.location.sellingonline && qu.quantity;
            });
        });
        if (finnd) {
            inStock = true;
        }
    }
    if (product && product.productimage) {
        imageArray = [`${config.apiImageUrl}/big/${product.productimage}`];
    } else {
        imageArray = [`${config.apiImageUrl}/big/images/no-image.png`];
    }

    if (product && product.formerprice && product.formerprice > product.price) {
        prices = (
            <React.Fragment>
                <span className="product__new-price">
                    <Currency value={product.price} />
                </span>{" "}
                <span className="product__old-price">
                    <Currency value={product.formerprice} />
                </span>
            </React.Fragment>
        );
    } else {
        prices = <Currency value={product && product.price ? product.price : 0} />;
    }
    const handleChangeQuantity = (quantity) => {
        setQuantity(quantity);
    };
    const handleType = (e) => {
        setType(e.target.value);
        if (e.target.value) {
            //setTypeErr("");
            const add = product.productvariations.find((q) => {
                return q._id === e.target.value;
            });

            setaddProduct({
                pvid: add._id,
                name: product.productname,
                image: product.productimage ? product.productimage : "",
                // ? `${config.apiImageUrl}/small/${product.productimage}`
                // : `${config.apiImageUrl}/small/images/no-image.png`,
                price: product.price,
                type: product.variablename,
                varname: add.varname,
                quantity: add.quantity.reduce(
                    (sum, pqu) => (pqu.location.sellingonline ? sum + pqu.quantity : sum + 0),
                    0
                ),
                pid: product._id,
            });
        } else {
            setaddProduct({
                pvid: "",
                name: "",
                image: "",
                type: "",
                varname: "",
                quantity: 0,
                price: 0,
                pid: "",
            });
        }
    };
    return (
        <div className={`product product--layout--${layout}`}>
            <div className="product__content">
                <ProductGallery layout={layout} images={imageArray} />

                <div className="product__info">
                    <div className="product__wishlist-compare">
                        <AsyncAction
                            action={() => wishlistAddItem(product)}
                            render={({ run, loading }) => (
                                <button
                                    type="button"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Wishlist"
                                    onClick={run}
                                    className={classNames("btn btn-sm btn-light btn-svg-icon", {
                                        "btn-loading": loading,
                                    })}
                                >
                                    <Wishlist16Svg />
                                </button>
                            )}
                        />
                    </div>
                    <h1 className="product__name">{product && product.productname ? product.productname : ""}</h1>
                    {/* <div className="product__rating">
                            <div className="product__rating-stars">
                                <Rating value={product.rating} />
                            </div>
                            <div className="product__rating-legend">
                                <Link to="/">{`${product.reviews} Reviews`}</Link>
                                <span>/</span>
                                <Link to="/">Write A Review</Link>
                            </div>
                        </div> */}
                    <div className="product__description">
                        {product && product.description ? parse(parse(product.description)) : ""}
                    </div>
                    {/* <ul className="product__features">
                            <li>Speed: 750 RPM</li>
                            <li>Power Source: Cordless-Electric</li>
                            <li>Battery Cell Type: Lithium</li>
                            <li>Voltage: 20 Volts</li>
                            <li>Battery Capacity: 2 Ah</li>
                        </ul> */}
                    <ul className="product__meta">
                        <li className="product__meta-availability">
                            Availability:{" "}
                            {inStock ? (
                                <span className="text-success">In Stock</span>
                            ) : (
                                <span className="text-danger">Out Of Stock</span>
                            )}
                        </li>
                        <li>
                            Brand: {product && product.brand && product.brand.brandname ? product.brand.brandname : "-"}
                        </li>
                        <li>Product Code: {product && product.productcode ? product.productcode : "-"}</li>
                    </ul>
                </div>

                <div className="product__sidebar">
                    {/* <div className="product__availability">
                            Availability: <span className="text-success">In Stock</span>
                        </div> */}

                    <div className="product__prices">{prices}</div>

                    <form className="product__options">
                        <div className="form-group product__option">
                            {product && !product.variablename ? <p>{addProduct.quantity} available.</p> : ""}
                            {product && product.variablename ? (
                                <>
                                    <label htmlFor="product-quantity" className="product__option-label">
                                        {product.variablename}
                                    </label>
                                    <div className="product__actions">
                                        <div className="product__actions-item">
                                            <select
                                                value={type}
                                                onChange={handleType}
                                                className="custom-select custom-select-lg"
                                            >
                                                <option value="">Please select:</option>
                                                {product.productvariations.map((qua) => (
                                                    <option key={qua._id} value={qua._id}>
                                                        {qua.varname} (
                                                        {qua.quantity.reduce(
                                                            (sum, pqu) =>
                                                                pqu.location.sellingonline
                                                                    ? sum + pqu.quantity
                                                                    : sum + 0,
                                                            0
                                                        )}{" "}
                                                        available)
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="form-group product__option">
                            <label htmlFor="product-quantity" className="product__option-label">
                                Quantity
                            </label>
                            <div className="product__actions">
                                <div className="product__actions-item">
                                    <InputNumber
                                        id="product-quantity"
                                        aria-label="Quantity"
                                        className="product__quantity"
                                        disabled={!addProduct.quantity}
                                        size="lg"
                                        min={1}
                                        max={addProduct.quantity}
                                        value={quantity}
                                        onChange={handleChangeQuantity}
                                    />
                                </div>
                                <div className="product__actions-item product__actions-item--addtocart">
                                    <AsyncAction
                                        action={() => cartAddItem(addProduct, quantity)}
                                        render={({ run, loading }) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                disabled={!quantity || !addProduct.quantity}
                                                className={classNames("btn btn-primary btn-lg", {
                                                    "btn-loading": loading,
                                                })}
                                            >
                                                Add to cart
                                            </button>
                                        )}
                                    />
                                </div>
                                <div className="product__actions-item product__actions-item--wishlist">
                                    <AsyncAction
                                        action={() => wishlistAddItem(product)}
                                        render={({ run, loading }) => (
                                            <button
                                                type="button"
                                                data-toggle="tooltip"
                                                title="Wishlist"
                                                onClick={run}
                                                className={classNames("btn btn-secondary btn-svg-icon btn-lg", {
                                                    "btn-loading": loading,
                                                })}
                                            >
                                                <Wishlist16Svg />
                                            </button>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                {/* <div className="product__footer">
                    <div className="product__tags tags">
                        <div className="tags__list">
                            <Link to="/">Mounts</Link>
                            <Link to="/">Electrodes</Link>
                            <Link to="/">Chainsaws</Link>
                        </div>
                    </div>

                    <div className="product__share-links share-links">
                        <ul className="share-links__list">
                            <li className="share-links__item share-links__item--type--like">
                                <Link to="/">Like</Link>
                            </li>
                            <li className="share-links__item share-links__item--type--tweet">
                                <Link to="/">Tweet</Link>
                            </li>
                            <li className="share-links__item share-links__item--type--pin">
                                <Link to="/">Pin It</Link>
                            </li>
                            <li className="share-links__item share-links__item--type--counter">
                                <Link to="/">4K</Link>
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </div>
    );
}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
};

export default connect(() => ({}), mapDispatchToProps)(Product);
