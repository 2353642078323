// react
import React, { useRef, useState, useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";

// application
import PageHeader from "../shared/PageHeader";
import { config, recapta_site_key } from "../../data/custom";
import { setPriceFilter, setBrandCheck } from "../../store/app";
import MessageAlert from "../errors/MessageAlert";
import ErrorLoading from "../errors/ErrorLoading";

// data stubs
import theme from "../../data/theme";
import BlockLoader from "../blocks/BlockLoader";

function AccountResetOtp({ priceFilter, brandCheck, setPriceFilter, setBrandCheck, customer, reload }) {
    const { handleSubmit, register, errors, reset } = useForm();
    const [alertMsg, setAlertMsg] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errMsg, seterrMsg] = useState([]);
    const [load, setLoad] = useState(false);
    const history = useHistory();
    const recaptchaRef = useRef(null);
    const { auth } = useParams();
    useEffect(() => {
        if (customer) {
            return history.push("");
        }
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        seterrMsg([]);
        setIsLoading(true);
        fetch(`${config.apiUrl}/auth/resetotp/${auth}`, {
            method: "GET",
            crossDomain: true,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    setIsLoading(false);
                } else {
                    seterrMsg(data.error);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["Please check your internet connection and reload the page"]);
                } else {
                    seterrMsg(["An error occured!"]);
                }
                setIsLoading(false);
            });
    }, [customer, brandCheck, priceFilter, setBrandCheck, setPriceFilter, auth, reload, history]);
    if (isLoading) {
        return <BlockLoader />;
    }
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Reset OTP", url: "" },
    ];
    const onSubmit = async (values) => {
        setLoad(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setLoad(false);
            return toast.error("No internet connection found!");
        }
        fetch(`${config.apiUrl}/auth/resetotp/${auth}`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    reset();
                    history.push(`/reset-password/${data.data.resetToken}`);
                } else {
                    setAlertMsg(data.error);
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("No internet connection found!");
                } else {
                    toast.error("An error occured!");
                }
                setLoad(false);
            });
    };
    let content;
    if (errMsg && errMsg.length) {
        content = (
            <ErrorLoading
                title={"This order can not be shown!"}
                errMsg={errMsg}
                reloadNow={true}
                isLoading={isLoading}
                //removeBorder={true}
            />
        );
    } else {
        content = (
            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <div className="card-title text-center">
                                        <h1 className="pt-lg-0 pt-2">Authorization Code</h1>
                                    </div>
                                    <p className="mb-4 pt-2">
                                        Enter the One Time Authorization Code(OTP) sent to your phone to access reset
                                        password.
                                    </p>
                                    {alertMsg.length ? (
                                        <div className="">
                                            <MessageAlert setAlertMsg={setAlertMsg}>
                                                {alertMsg.map((alertM, index) => (
                                                    <strong key={index}>{alertM}</strong>
                                                ))}{" "}
                                            </MessageAlert>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label htmlFor="resetOtp">
                                                OTP Code <sup className="text-danger"> *</sup>
                                            </label>
                                            <input
                                                id="resetOtp"
                                                autoComplete="off"
                                                name="resetOtp"
                                                type="text"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.resetOtp,
                                                })}
                                                placeholder="Enter OTP Code Sent Here..."
                                                ref={register({
                                                    required: "Please enter the OTP Code.",
                                                    minLength: {
                                                        value: 6,
                                                        message: "Code is to short",
                                                    },
                                                    maxLength: {
                                                        value: 6,
                                                        message: "Code is to long",
                                                    },
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Invalid Code.",
                                                    },
                                                })}
                                            />
                                            {errors.resetOtp && (
                                                <div className="invalid-feedback">{errors.resetOtp.message}</div>
                                            )}
                                        </div>
                                        <div className="pt-3">
                                            <button
                                                type="submit"
                                                className={classNames("btn btn-primary btn-lg btn-block", {
                                                    "btn-loading": load,
                                                })}
                                            >
                                                Send OTP Code
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title text-center">Login</h3>
                                    <p>If you have already registered you can login by clicking the button below.</p>
                                    <Link to="/login">
                                        <button type="submit" className="btn btn-block btn-dark mt-2 mt-md-3 mt-lg-4">
                                            Login
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Authorization Code — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
            {content}
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    reload: state.app.reload,
    customer: state.app.customer.name,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountResetOtp);
