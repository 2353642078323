// react
import React from "react";

export default function FooterContacts({ webcontactintro, webcontactaddress, webemail, webphone, webworkingtime }) {
    return (
        <div className="site-footer__widget footer-contacts">
            <h5 className="footer-contacts__title">Contact Us</h5>

            <div className="footer-contacts__text">{webcontactintro}</div>

            <ul className="footer-contacts__contacts">
                <li>
                    <i className="footer-contacts__icon fas fa-globe-americas" />
                    {webcontactaddress}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-envelope" />
                    {webemail}
                </li>
                <li>
                    <i className="footer-contacts__icon fas fa-mobile-alt" />
                    {webphone}
                </li>
                <li>
                    <i className="footer-contacts__icon far fa-clock" />
                    {webworkingtime}
                </li>
            </ul>
        </div>
    );
}
