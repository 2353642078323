const {
    REACT_APP_ENV,
    REACT_APP_API_URL,
    REACT_APP_API_URL_DEV,
    REACT_APP_API_IMAGEURL,
    REACT_APP_API_IMAGEURL_DEV,
    REACT_APP_PAYSTACK_KEY,
    REACT_APP_PAYSTACK_KEY_DEV,
    REACT_APP_FLUTTERWAVE_KEY,
    REACT_APP_FLUTTERWAVE_KEY_DEV,
    REACT_APP_RECAPTA_SITE_KEY,
    REACT_APP_RECAPTA_SITE_KEY_DEV,
    REACT_APP_GA_TRACKING_ID,
    REACT_APP_GA_TRACKING_ID_DEV,
} = process.env;
const apiUrl = REACT_APP_ENV === "production" ? REACT_APP_API_URL : REACT_APP_API_URL_DEV;
const apiImageUrl = REACT_APP_ENV === "production" ? REACT_APP_API_IMAGEURL : REACT_APP_API_IMAGEURL_DEV;
export const config = {
    apiUrl,
    apiImageUrl,
};
export const flutterwaveLogo = `${REACT_APP_API_IMAGEURL}/siteimg.jpg`;
export const paystackKey = REACT_APP_ENV === "production" ? REACT_APP_PAYSTACK_KEY : REACT_APP_PAYSTACK_KEY_DEV;
export const flutterwaveKey =
    REACT_APP_ENV === "production" ? REACT_APP_FLUTTERWAVE_KEY : REACT_APP_FLUTTERWAVE_KEY_DEV;
export const recapta_site_key =
    REACT_APP_ENV === "production" ? REACT_APP_RECAPTA_SITE_KEY : REACT_APP_RECAPTA_SITE_KEY_DEV;
export const GATrackingId = REACT_APP_ENV === "production" ? REACT_APP_GA_TRACKING_ID : REACT_APP_GA_TRACKING_ID_DEV;
export const youtubeUrl = "";
export const setCookie = (cname, cvalue, exdays = "") => {
    if (exdays) {
        let d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        exdays = "expires=" + d.toUTCString();
    }
    document.cookie = cname + "=" + JSON.stringify(cvalue) + ";" + exdays + ";path=/";
};

export const deleteCookie = (cname) => {
    document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

export const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
export const limitStr = (str, len) => {
    const newString = str.substring(0, len);
    if (str === newString) {
        return str;
    } else {
        return newString + "...";
    }
};
export const randomId = (length) => {
    let result = "";
    const chars = "0123456789ABCDEFGHIJKLM0123456789NOPQRSTUVWXYZ0123456789";
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
};
export const getUsr = (user) => {
    return {
        id: user._id,
        name: user.name,
        number: user.number,
        email: user.email,
    };
};

export const convFees = (amount) => {
    if (amount <= 2462.49) {
        return amount / 0.985 - amount;
    } else if (amount > 2462.49 && amount < 124666) {
        return (amount + 100) / 0.985 - amount;
    } else {
        return 2000;
    }
};
