// react
import React, { useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga";

// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import ProductTabs from "./ProductTabs";
import ErrorLoading from "../errors/ErrorLoading";
import { url } from "../../services/utils";
import { setPriceFilter, setBrandCheck } from "../../store/app";

// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";

// data stubs
import { config, GATrackingId } from "../../data/custom";
import { setHideTopAlert } from "../../store/menu";

function ShopPageProduct(props) {
    const { slug } = useParams();
    const { productSlug, layout, priceFilter, brandCheck, setPriceFilter, setBrandCheck, reload, setHideTopAlert } =
        props;
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState("");
    const [type, setType] = useState("");
    const [prodError, setProdError] = useState([]);
    const [addProduct, setaddProduct] = useState({});
    const [relatedProducts, setRelatedProducts] = useState([]);
    useEffect(() => {
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        setHideTopAlert(false);
        setProdError([]);
        setIsLoading(true);
        fetch(`${config.apiUrl}/products/slug/${slug}`, {
            method: "GET",
            crossDomain: true,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    const rda = data.data;
                    const increment = data.increment;
                    setProduct(increasePrice(rda, increment));
                    if (!rda.variablename) {
                        setaddProduct({
                            pvid: rda.productvariations[0]._id,
                            name: rda.productname,
                            // image: rda.productimage
                            //     ? `${config.apiImageUrl}/small/${rda.productimage}`
                            //     : `${config.apiImageUrl}/small/images/no-image.png`,
                            image: rda.productimage ? rda.productimage : "",
                            price: rda.price + (rda.price * increment) / 100,
                            type: rda.variablename,
                            varname: "",
                            quantity: rda.productvariations[0].quantity.reduce(
                                (sum, pqu) => (pqu.location.sellingonline ? sum + pqu.quantity : sum + 0),
                                0
                            ),
                            pid: rda._id,
                        });
                        setType(rda.productvariations[0]._id);
                    } else {
                        setaddProduct({
                            pvid: "",
                            name: "",
                            image: "",
                            type: "",
                            varname: "",
                            quantity: 0,
                            price: 0,
                            pid: "",
                        });
                    }
                    GATrackingId && ReactGA.initialize(GATrackingId);
                    GATrackingId && ReactGA.pageview(window.location.pathname);
                    // Load related products.
                    setRelatedProducts(increasePrices(data.relatedProducts, increment));
                    setIsLoading(false);
                } else {
                    setProdError(["Product was not found"]);
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    setProdError(["Please check your internet connection and reload the page"]);
                } else {
                    setProdError(["An error occured!"]);
                }
                setIsLoading(false);
            });
    }, [
        productSlug,
        setIsLoading,
        priceFilter,
        brandCheck,
        setPriceFilter,
        setBrandCheck,
        slug,
        reload,
        setHideTopAlert,
    ]);

    const increasePrice = (productData, increment) => {
        return { ...productData, price: productData.price + (productData.price * increment) / 100 };
    };
    const increasePrices = (productsData, increment) => {
        const newData = [...productsData].map((product) => {
            return { ...product, price: product.price + (product.price * increment) / 100 };
        });
        return newData;
    };
    if (isLoading) {
        return <BlockLoader />;
    }
    const breadcrumb = [
        { title: "Home", url: url.home() },
        { title: product.productname, url: url.product(product) },
    ];

    let content;

    if (prodError && prodError.length) {
        content = <ErrorLoading errMsg={prodError} reloadNow={true} isLoading={isLoading} />;
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Product
                            product={product}
                            type={type}
                            setType={setType}
                            addProduct={addProduct}
                            setaddProduct={setaddProduct}
                            layout={layout}
                        />
                        <ProductTabs product={product} />
                    </div>
                </div>

                {relatedProducts.length > 0 && (
                    <BlockProductsCarousel title="Related Products" layout="grid-5" products={relatedProducts} />
                )}
            </React.Fragment>
        );
    }

    // }

    return (
        <React.Fragment>
            <Helmet>
                <title>{product.productname}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

ShopPageProduct.propTypes = {
    /** Product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
};

ShopPageProduct.defaultProps = {
    layout: "standard",
};

const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    reload: state.app.reload,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
    setHideTopAlert: (boolean) => setHideTopAlert(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageProduct);
