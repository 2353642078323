// react
import React, { useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { setPriceFilter, setBrandCheck } from "../../store/app";
//import { config } from "../../data/custom";
// data stubs
import theme from "../../data/theme";

const AccountPageDashboard = ({ priceFilter, brandCheck, setPriceFilter, setBrandCheck, customer }) => {
    useEffect(() => {
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
    }, [priceFilter, brandCheck, setPriceFilter, setBrandCheck]);

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`My Account — ${theme.name}`}</title>
            </Helmet>
            <div className="dashboard__address card address-card address-card--featured">
                <div className="address-card__badge">Profile</div>
                <div className="address-card__body">
                    <div className="address-card__name">{customer.name}</div>
                    {/* <div className="address-card__row">
                        {address.country}
                        <br />
                        {address.postcode},{address.city}
                        <br />
                        {address.address}
                    </div> */}
                    <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{customer.number}</div>
                    </div>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Email Address</div>
                        <div className="address-card__row-content">{customer.email ? customer.email : "-"}</div>
                    </div>
                    <div className="address-card__footer">
                        <Link to="/account/profile">Edit Profile</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    customer: state.app.customer,
    reload: state.app.reload,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountPageDashboard);
