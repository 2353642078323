import React from "react";

const MessageAlert = ({ setAlertMsg, children, color }) => {
    const closeMessageAlert = () => {
        setAlertMsg([]);
    };
    return (
        <div className={`alert alert-${color ? color : "danger"} alert-dismissible`}>
            {children}

            <button type="button" className="close" onClick={closeMessageAlert}>
                <span>&times;</span>
            </button>
        </div>
    );
};

export default MessageAlert;
