import React from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";

const CancelShippingModal = ({ show2, setShow2, load, requestDeliveryMode }) => {
    const handleClose = () => setShow2(false);
    return (
        <Modal show={show2} onHide={handleClose} size="lg">
            <Modal.Body>
                Do you want to cancel your shipping? This order will be set to Self Pickup. Click confirm to process.
            </Modal.Body>
            <Modal.Footer>
                <button disabled={load ? true : false} className="btn btn-sm btn-dark" onClick={handleClose}>
                    Close
                </button>
                <button
                    className={classNames("btn btn-sm btn-danger", {
                        "btn-loading": load,
                    })}
                    disabled={load ? true : false}
                    onClick={() => requestDeliveryMode({}, "self")}
                >
                    Confirm
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default CancelShippingModal;
