import React from "react";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";

const YoutubeRequestModal = ({ show, setShow, youtubeUrl }) => {
    const handleClose = () => setShow(false);
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered size="lg">
                {/* <Modal.Header closeButton>
                    <Modal.Title>Cutting List Requirement</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="player-wrapper">
                        <ReactPlayer
                            className="react-player"
                            url={youtubeUrl}
                            width="100%"
                            height="100%"
                            playing={true}
                            loop={true}
                        />
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <button className="btn btn-dark" onClick={handleClose}>
                        Cancel
                    </button>
                </Modal.Footer> */}
            </Modal>
        </div>
    );
};

export default YoutubeRequestModal;
