// react
import React, { useEffect } from "react";

// third-party
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// application
import { ArrowRoundedRight6x9Svg } from "../../svg";
import { fetchMenu } from "../../store/menu";
import { setPriceFilter, setBrandCheck } from "../../store/app";

function DepartmentsLinks({ menuData, fetchMenu, setPriceFilter, setBrandCheck, handleButtonClick, reload }) {
    useEffect(() => {
        menuData && !menuData.length && fetchMenu();
    }, [fetchMenu, menuData, reload]);
    let history = useHistory();
    const resetPBFilter = (e, to) => {
        e.preventDefault();
        setPriceFilter(false);
        setBrandCheck(false);
        handleButtonClick();
        history.push(to);
    };
    const linksList = menuData.map((department, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = "";
        const columns = department.titles.map((column, index) => {
            let title = null;
            let allLinks = [];
            let subLinks = null;

            title = (
                <Link onClick={(e) => resetPBFilter(e, `/products/${column.slug}/0-${column._id}-0`)} to="/">
                    {column.titlename}
                </Link>
            );

            if (column.submenus && column.submenus.length) {
                allLinks = column.submenus.map((sub) => {
                    return (
                        <li key={sub._id} className="megamenu__item">
                            <Link onClick={(e) => resetPBFilter(e, `/products/${sub.slug}/0-0-${sub._id}`)} to="/">
                                {sub.submenuname}
                            </Link>
                        </li>
                    );
                });
                subLinks = <ul className="megamenu__links megamenu__links--level--1">{allLinks}</ul>;
            }

            const linksList = (
                <li key={column._id} className="text-capitalize megamenu__item megamenu__item--with-submenu">
                    {title}
                    {subLinks}
                </li>
            );
            return (
                <div key={index} className="col-3">
                    <ul className={`megamenu__links megamenu__links--level--0`}>{linksList}</ul>
                </div>
            );
        });
        if (department.titles) {
            arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
            submenu = (
                <div className={`departments__megamenu departments__megamenu--xl`}>
                    <div className={`megamenu megamenu--department`}>
                        <div className="row">{columns}</div>
                    </div>
                </div>
            );
        }

        return (
            <li key={index} className={`text-uppercase departments__item ${itemClass}`}>
                <Link onClick={(e) => resetPBFilter(e, `/products/${department.slug}/${department._id}-0-0`)} to="/">
                    {department.categoryname}
                    {arrow}
                </Link>
                {submenu}
            </li>
        );
    });
    return <ul className="departments__links">{linksList}</ul>;
}
DepartmentsLinks.propTypes = {
    links: PropTypes.array,
    fetchMenu: PropTypes.func,
};

DepartmentsLinks.defaultProps = {
    links: [],
};

const mapStateToProps = (state) => {
    return {
        menuData: state.menu.menuDesktop,
        reload: state.app.reload,
    };
};

const mapDispatchToProps = {
    fetchMenu: () => fetchMenu(),
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentsLinks);
