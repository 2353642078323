// react
import React from "react";

// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";

function Currency(props) {
    const { value, currency, currentCurrency, numb } = props;
    const { symbol } = currency || currentCurrency;
    return (
        <NumberFormat
            value={!numb ? value.toFixed(2) : value}
            displayType={"text"}
            thousandSeparator={true}
            prefix={!numb ? symbol : false}
        />
    );
}

Currency.propTypes = {
    /** price value */
    value: PropTypes.number.isRequired,
    /** currency object, specify to override currentCurrency */
    currency: PropTypes.object,
    /** currency object */
    currentCurrency: PropTypes.object,
};

const mapStateToProps = (state) => ({
    currentCurrency: state.currency,
});

export default connect(mapStateToProps)(Currency);
