/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
import qs from "query-string";
import { config } from "../data/custom";

const shopApi = {
    /**
     * Returns an array of most popular products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getPopularProducts: (options = {}) => {
        return fetch(`${config.apiUrl}/products?${qs.stringify(options)}`).then((response) => response.json());
    },
};

export default shopApi;
