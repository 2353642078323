// react
import React from "react";

import parse from "html-react-parser";

const ProductTabDescription = ({ product }) => {
    return (
        <div className="typography">
            <h3>Product Description</h3>
            {product && product.description ? parse(parse(product.description)) : ""}
        </div>
    );
};

export default ProductTabDescription;
