import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import InputNumber from "../shared/InputNumber";

const FactoryModal = ({ modal, setModal, edit, editRowItem, rowNo }) => {
    const [val, setVal] = useState({});
    useEffect(() => {
        setVal(edit);
    }, [edit]);
    const handleClose = () => setModal(false);
    const saveResult = () => {
        editRowItem(val, rowNo);
        setModal(false);
    };
    return (
        <div>
            <Modal show={modal} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Row Number {rowNo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-row">
                        <div className="col-6 col-sm-6 col-md-4 mb-3">
                            <label htmlFor="len">Length in MM</label>
                            <InputNumber
                                id="len"
                                aria-label="len"
                                size="sm"
                                min={1}
                                value={val.len}
                                onChange={(len) => setVal({ ...val, len })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 mb-3">
                            <label htmlFor="wid">Width in MM</label>
                            <InputNumber
                                id="wid"
                                aria-label="wid"
                                size="sm"
                                min={1}
                                value={val.wid}
                                onChange={(wid) => setVal({ ...val, wid })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 mb-3">
                            <label htmlFor="qty">Quantity</label>
                            <InputNumber
                                id="qty"
                                aria-label="qty"
                                size="sm"
                                min={1}
                                value={val.qty}
                                onChange={(qty) => setVal({ ...val, qty })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 mb-3">
                            <label htmlFor="eLen">Edge Lenth in MM</label>
                            <InputNumber
                                id="eLen"
                                aria-label="eLen"
                                size="sm"
                                min={0}
                                value={val.eLen}
                                onChange={(eLen) => setVal({ ...val, eLen })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 mb-3">
                            <label htmlFor="eWid">Edge Width in MM</label>
                            <InputNumber
                                id="eWid"
                                aria-label="eWid"
                                size="sm"
                                min={0}
                                value={val.eWid}
                                onChange={(eWid) => setVal({ ...val, eWid })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 mb-3">
                            <label htmlFor="inst">Special Instructions</label>
                            <input
                                type="text"
                                id="inst"
                                aria-label="inst"
                                className="form-control form-control-sm"
                                value={val.inst}
                                onChange={(e) => setVal({ ...val, inst: e.target.value })}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-dark" onClick={handleClose}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-primary"
                        disabled={JSON.stringify(edit) === JSON.stringify(val) ? true : false}
                        onClick={saveResult}
                    >
                        Save Edit
                    </button>{" "}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default FactoryModal;
