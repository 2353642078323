export default [
    {
        key: "pickup",
        title: "Self Pickup",
        description: `By picking this option, you agree to pick up your order at our office.
            A text message will be sent to you once your order is ready for pick up. 
            You can also track you order status on your account dashboard once you are logged in `,
    },
    {
        key: "check",
        title: "Shipment",
        description: `By picking this option, We will send you the delivery fee to your entered address after you have paid 
        for your order. Our delivery is only to address within lagos at the moment. If your address is outside of Lagos, 
        you will be required to pay the fee requested by the local transport company in addition to the delivery fee from our office 
        to the park. You can also chat with our customer support to know how much you will be pay for our delivery service.`,
    },
];
