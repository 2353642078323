// react
import React, { useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// application
import ProductCardNew from "../shared/ProductCardNew";
import { Filters16Svg, LayoutGrid16x16Svg } from "../../svg";
import { sidebarOpen } from "../../store/sidebar";
import { setReload } from "../../store/app";
import Currency from "../shared/Currency";

function ProductsViewNew(props) {
    const {
        searchPage,
        isLoading,
        isLoadingMore,
        productsData,
        errMsg,
        //filters,
        sort,
        limit,
        count,
        totalcount,
        dispatch,
        layout: propsLayout,
        grid,
        offcanvas,
        loadMore,
        nextpage,
        sidebarOpen,
        setReload,
    } = props;
    const [layout, setLayout] = useState(propsLayout);
    const handleSortChange = (e) => dispatch({ type: "SET_SORT", payload: e.target.value });
    const handleLimitChange = (e) => dispatch({ type: "SET_LIMIT", payload: e.target.value });
    // const filtersCount = Object.keys(filters)
    //     .map((x) => filters[x])
    //     .filter((x) => x).length;
    let viewModes = [{ key: "grid", title: "Grid", icon: <LayoutGrid16x16Svg /> }];

    viewModes = viewModes.map((viewMode) => {
        const className = classNames("layout-switcher__button", {
            "layout-switcher__button--active": layout === viewMode.key,
        });

        return (
            <button
                key={viewMode.key}
                title={viewMode.title}
                type="button"
                className={className}
                onClick={() => setLayout(viewMode.key)}
            >
                {viewMode.icon}
            </button>
        );
    });

    const productsListItems = productsData.map((product) => (
        <div key={product._id} className="products-list__item">
            <ProductCardNew product={product} />
        </div>
    ));

    const rootClasses = classNames("products-view", {
        "products-view--loading": isLoading,
    });

    const viewOptionsClasses = classNames("view-options", {
        "view-options--offcanvas--always": offcanvas === "always",
        "view-options--offcanvas--mobile": offcanvas === "mobile",
    });
    const reloadPageNow = () => {
        setReload(Math.random());
    };
    let reloadPage;

    let err_msg;
    if (errMsg && errMsg.length) {
        err_msg = errMsg.map((er, index) => (
            <div key={index} className="products-view__empty-subtitle p-4">
                {er}
            </div>
        ));
        reloadPage = (
            <div className="text-center">
                <button
                    disabled={isLoading ? true : false}
                    type="button"
                    className={classNames("btn btn-primary", {
                        "btn-loading": isLoading,
                    })}
                    onClick={reloadPageNow}
                >
                    Reload Page
                </button>
            </div>
        );
    } else {
        err_msg = (
            <div className="products-view__empty-subtitle p-4">
                We couldn't find a product that matched your request. Select other categories from the menu or resetting
                the filters.
            </div>
        );
    }
    let content;
    const topcount = totalcount > 1 ? "products" : "product";
    if (productsListItems.length > 0) {
        content = (
            <div className="products-view__content">
                <div className="products-view__options">
                    <div className={viewOptionsClasses}>
                        {!searchPage && (
                            <div className="view-options__filters-button">
                                <button type="button" className="filters-button" onClick={() => sidebarOpen()}>
                                    <Filters16Svg className="filters-button__icon" />
                                    <span className="filters-button__title">Filters</span>
                                    {/* {!!filtersCount && <span className="filters-button__counter">{filtersCount}</span>} */}
                                </button>
                            </div>
                        )}
                        <div className="view-options__layout">
                            <div className="layout-switcher">
                                <div className="layout-switcher__list">{viewModes}</div>
                            </div>
                        </div>
                        <div className="view-options__legend">
                            Showing <Currency value={count} numb={true} /> of{" "}
                            <Currency value={totalcount} numb={true} /> {topcount}
                        </div>
                        <div className="view-options__divider" />
                        {!searchPage && (
                            <div className="view-options__control">
                                <label htmlFor="view-options-sort">Sort By</label>
                                <div>
                                    <select
                                        id="view-options-sort"
                                        className="form-control form-control-sm"
                                        value={sort}
                                        onChange={(e) => handleSortChange(e)}
                                    >
                                        <option value="-createdAt">New to Old</option>
                                        <option value="createdAt">Old to New</option>
                                        <option value="price">Price - Low To High</option>
                                        <option value="-price">Price - High To Low</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        <div className="view-options__control">
                            <label htmlFor="view-options-limit">Show</label>
                            <div>
                                <select
                                    id="view-options-limit"
                                    className="form-control form-control-sm"
                                    value={limit}
                                    onChange={(e) => handleLimitChange(e)}
                                >
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="products-view__list products-list"
                    data-layout={layout !== "list" ? grid : layout}
                    data-with-features={layout === "grid-with-features" ? "true" : "false"}
                >
                    <div className="products-list__body">{productsListItems}</div>
                </div>

                <div className="loadprd products-view__pagination">
                    <div className="text-center">
                        <p className="text-muted text-center">
                            You've viewed <Currency value={count} numb={true} /> of{" "}
                            <Currency value={totalcount} numb={true} /> {topcount}
                        </p>
                    </div>
                    <div className="justify-content-center">
                        <div className="showProgress progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${(count * 100) / totalcount}%` }}
                                aria-valuenow={(count * 100) / totalcount}
                                aria-valuemin="0"
                                aria-valuemax="100"
                            ></div>
                        </div>
                    </div>
                    {nextpage ? (
                        <div className="mt-4">
                            <button
                                disabled={isLoadingMore ? true : false}
                                type="button"
                                className={classNames("btn btn-outline-secondary btn-lg btn-block", {
                                    "btn-loading": isLoadingMore,
                                })}
                                onClick={loadMore}
                            >
                                LOAD MORE
                            </button>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        );
    } else {
        content = (
            <div className="products-view__empty">
                <div className="products-view__empty-title">No product was found!</div>
                {err_msg}
                {reloadPage}
            </div>
        );
    }

    return (
        <div className={rootClasses}>
            <div className="products-view__loader" />
            {content}
        </div>
    );
}

ProductsViewNew.propTypes = {
    /**
     * Indicates that products is loading.
     */
    isLoading: PropTypes.bool,
    /**
     * ProductsList object.
     */
    productsList: PropTypes.object,
    /**
     * Products list options.
     */
    options: PropTypes.object,
    /**
     * Products list filters.
     */
    filters: PropTypes.object,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * products list layout (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    layout: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * products list layout (default: 'grid')
     * one of ['grid-3-sidebar', 'grid-4-full', 'grid-5-full']
     */
    grid: PropTypes.oneOf(["grid-3-sidebar", "grid-4-full", "grid-5-full"]),
    /**
     * indicates when sidebar should be off canvas
     */
    offcanvas: PropTypes.oneOf(["always", "mobile"]),
};

ProductsViewNew.defaultProps = {
    layout: "grid",
    grid: "grid-3-sidebar",
    offcanvas: "mobile",
};

const mapDispatchToProps = {
    setReload: (value) => setReload(value),
    sidebarOpen,
};

export default connect(() => ({}), mapDispatchToProps)(ProductsViewNew);
