export default {
    name: "Optracoy",
    fullName: "Optracoy - Olorunwapelumi Trading Company Limited",
    //url: "https://themeforest.net/item/stroyka-tools-store-react-ecommerce-template/23909258",
    author: {
        name: "Doxait",
        profile_url: "https://doxait.com",
    },
    // contacts: {
    //     address: "715 Fake Street, New York 10021 USA",
    //     email: "stroyka@example.com",
    //     phone: "(800) 060-0730",
    // },
};
