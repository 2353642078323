import {
    LOAD_USR,
    PRICE_FILTER,
    BRAND_CHECK,
    SLIDER_PICTURES,
    FEATURED_PRODUCTS,
    SALES_PRODUCTS,
    BRAND_IMAGES,
    RELOAD,
    SEARCH,
} from "./appActionTypes";

export const loadU_S_R = (name) => {
    return {
        type: LOAD_USR,
        payload: name,
    };
};
export const setPriceFilter = (boolean) => {
    return {
        type: PRICE_FILTER,
        payload: boolean,
    };
};
export const setBrandCheck = (boolean) => {
    return {
        type: BRAND_CHECK,
        payload: boolean,
    };
};
export const setSliderPictures = (sliders) => {
    return {
        type: SLIDER_PICTURES,
        payload: sliders,
    };
};
export const setFeaturedProducts = (products) => {
    return {
        type: FEATURED_PRODUCTS,
        payload: products,
    };
};
export const setSalesProducts = (products) => {
    return {
        type: SALES_PRODUCTS,
        payload: products,
    };
};
export const setBrandImages = (brands) => {
    return {
        type: BRAND_IMAGES,
        payload: brands,
    };
};
export const setReload = (value) => {
    return {
        type: RELOAD,
        payload: value,
    };
};
export const setSearch = (value) => {
    return {
        type: SEARCH,
        payload: value,
    };
};
