// react
import React from "react";

import { connect } from "react-redux";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";

function SocialLinks(props) {
    const { shape, className, socials } = props;
    const classes = classNames(className, "social-links", {
        "social-links--shape--circle": shape === "circle",
        "social-links--shape--rounded": shape === "rounded",
    });

    const items = socials.map((item) => (
        <li key={item.type} className="social-links__item">
            <a
                className={`social-links__link social-links__link--type--${item.type}`}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className={item.icon} />
            </a>
        </li>
    ));

    return (
        <div className={classes}>
            <ul className="social-links__list">{items}</ul>
        </div>
    );
}

SocialLinks.propTypes = {
    /**
     * rating value
     */
    shape: PropTypes.oneOf(["circle", "rounded"]),
    className: PropTypes.string,
};
SocialLinks.defaultProps = {
    shape: null,
};
const mapStateToProps = (state) => ({
    socials: state.menu.socials,
});
export default connect(mapStateToProps)(SocialLinks);
