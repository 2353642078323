// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import { Cross12Svg } from "../../svg";
import { url } from "../../services/utils";
import { wishlistRemoveItem } from "../../store/wishlist";

// data stubs
import theme from "../../data/theme";

import { config } from "../../data/custom";

function ShopPageWishlist(props) {
    const { wishlist, wishlistRemoveItem } = props;
    console.log("🚀 ~ file: ShopPageWishlist.jsx:25 ~ ShopPageWishlist ~ wishlist:", wishlist);
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Wishlist", url: "" },
    ];

    let content;

    if (wishlist.length) {
        const itemsList = wishlist.map((item) => {
            const image = (
                <div className="product-image">
                    <Link to={url.product(item)} className="product-image__body">
                        <img
                            className="product-image__img"
                            src={
                                item.productimage
                                    ? `${config.apiImageUrl}/small/${item.productimage}`
                                    : `${config.apiImageUrl}/small/images/no-image.png`
                            }
                            alt=""
                        />
                    </Link>
                </div>
            );

            const renderRemoveButton = ({ run, loading }) => {
                const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                    "btn-loading": loading,
                });

                return (
                    <button type="button" onClick={run} className={classes} aria-label="Remove">
                        <Cross12Svg />
                    </button>
                );
            };

            return (
                <tr key={item.id} className="wishlist__row">
                    <td className="wishlist__column wishlist__column--image">{image}</td>
                    <td className="wishlist__column wishlist__column--product">
                        <Link to={url.product(item)} className="wishlist__product-name">
                            {item.productname}
                        </Link>
                    </td>
                    <td className="wishlist__column wishlist__column--price">
                        <Currency value={item.price} />
                    </td>
                    <td className="wishlist__column wishlist__column--tocart">
                        <Link to={url.product(item)}>
                            <button type="button" className="btn btn-primary btn-sm">
                                View
                            </button>
                        </Link>
                    </td>
                    <td className="wishlist__column wishlist__column--remove">
                        <AsyncAction action={() => wishlistRemoveItem(item.id)} render={renderRemoveButton} />
                    </td>
                </tr>
            );
        });

        content = (
            <div className="block">
                <div className="container">
                    <table className="wishlist">
                        <thead className="wishlist__head">
                            <tr className="wishlist__row">
                                <th className="wishlist__column wishlist__column--image">Image</th>
                                <th className="wishlist__column wishlist__column--product">Product Name</th>
                                <th className="wishlist__column wishlist__column--price">Price</th>
                                <th className="wishlist__column wishlist__column--tocart" aria-label="Add to cart" />
                                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="wishlist__body">{itemsList}</tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">Your wish list is empty!</div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Wish List — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Wishlist" breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    wishlistRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageWishlist);
