// react
import React, { useState, useEffect, useRef } from "react";

// third-party
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";

// application
import Pagination from "../shared/Pagination";
import Currency from "../shared/Currency";
import { setPriceFilter, setBrandCheck } from "../../store/app";
import OrderDetailBottom from "./OrderDetailBottom";

// data stubs
import theme from "../../data/theme";
import { url } from "../../services/utils";
// blocks
import BlockLoader from "../blocks/BlockLoader";
import { config, getCookie } from "../../data/custom";
import ErrorLoading from "../errors/ErrorLoading";

const AccountPageOrders = ({ priceFilter, brandCheck, setPriceFilter, setBrandCheck, reload, customer }) => {
    const delRef = useRef(null);
    const [prev, setPrev] = useState(0);
    const [next, setNext] = useState(0);
    const [pages, setPages] = useState(1);
    const [pageNo, setPageNo] = useState(1);
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [errMsg, seterrMsg] = useState([]);
    const [show2, setShow2] = useState(false);
    const [order, setOrder] = useState("");
    const [paymentGateway, setPaymentGateway] = useState();

    useEffect(() => {
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        seterrMsg([]);
        setIsLoading(true);
        const cookies = getCookie("optc_token");
        const getOrds = fetch(
            `${config.apiUrl}/onlinesales?payment[in]=paid&payment[in]=reversed&sort=-createdAt&limit=10&page=${pageNo}`,
            {
                method: "GET",
                crossDomain: true,
                headers: {
                    authorization: `Bearer ${JSON.parse(cookies)}`,
                },
            }
        );
        const inf = fetch(`${config.apiUrl}/infos?select=tax,allowOnlinePayment,paymentGateway`, {
            method: "GET",
            crossDomain: true,
        });
        Promise.all([getOrds, inf])
            .then(async ([aa, bb, cc]) => {
                const a = await aa.json();
                const b = await bb.json();
                return [a, b];
            })
            .then((data) => {
                if (data[0].success === true) {
                    data[0].pagination.prev ? setPrev(data[0].pagination.prev.page) : setPrev(0);
                    data[0].pagination.next ? setNext(data[0].pagination.next.page) : setNext(0);
                    if (data[0].totalpages === 0) {
                        setPageNo(1);
                    }
                    setPages(data[0].totalpages);
                    setOrders(data[0].data);
                    setIsLoading(false);
                } else {
                    seterrMsg(data[0].error);
                    setIsLoading(false);
                }
                if (data[1].success === true) {
                    setPaymentGateway(data[1].data[0].paymentGateway);
                } else {
                    seterrMsg(["An error occured!"]);
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["Please check your internet connection and reload the page"]);
                } else {
                    seterrMsg(["An error occured!"]);
                }
                setIsLoading(false);
            });
    }, [brandCheck, priceFilter, setBrandCheck, setPriceFilter, reload, pageNo]);

    if (isLoading) {
        return <BlockLoader />;
    }
    const customDate = (date) => {
        const newDate = new Date(date);
        return newDate.toLocaleDateString();
    };
    const handleShow2 = (e, order, animatePay = false) => {
        e.preventDefault();
        setOrder(order);
        setShow2(true);
        animatePay &&
            setTimeout(() => {
                delRef && delRef.current && delRef.current.scrollIntoView({ behavior: "smooth" });
            }, 400);
    };
    const hideShow2 = (e) => {
        e.preventDefault();
        setOrder("");
        setShow2(false);
    };
    const handleStatus = (data) => {
        if (data.delivery) {
            return "Delivered";
        } else if (data.processed) {
            return "Processed";
        } else if (data.processing) {
            return "Processing";
        } else {
            return "Pending";
        }
    };

    const formatItem = (data) => {
        let formatedArray = [];
        if (data && data.onlinebaskets && data.onlinebaskets.length) {
            data.onlinebaskets.map((item) => {
                const getInx = formatedArray.findIndex((x) => x.pvid === item.productvariation._id);
                if (getInx === -1) {
                    formatedArray.push({
                        pvid: item.productvariation._id,
                        image: item.productvariation.product.productimage
                            ? item.productvariation.product.productimage
                            : "",
                        slug: item.productvariation.product.slug,
                        name: item.productname,
                        vartype: item.vartype,
                        varname: item.varname,
                        price: item.price,
                        count: item.count,
                        amount: item.amount,
                    });
                } else {
                    formatedArray[getInx].count = formatedArray[getInx].count + item.count;
                    formatedArray[getInx].amount = formatedArray[getInx].amount + item.amount;
                }
                return item;
            });
        }
        return formatedArray;
    };
    let content;
    if (errMsg && errMsg.length) {
        content = (
            <ErrorLoading
                title={"This order can not be shown!"}
                errMsg={errMsg}
                reloadNow={true}
                isLoading={isLoading}
                removeBorder={true}
            />
        );
    } else if (orders.length) {
        const ordersList = orders.map((order) => (
            <tr key={order._id}>
                <td>
                    <Link to="/" onClick={(e) => handleShow2(e, order)}>{`${order.referenceno}`}</Link>
                </td>
                {/* <td className="text-capitalize">{order.payment}</td> */}
                <td>
                    {order.deliverytype === "self" ? (
                        "Self Pickup"
                    ) : order.deliveryCost ? (
                        <>
                            <Currency value={order.deliveryCost} />
                            {!order.deliveryCostPaid && (
                                <>
                                    <br />
                                    <Link to="/" onClick={(e) => handleShow2(e, order, true)}>
                                        <span className="badge badge-danger" style={{ cursor: "pointer" }}>
                                            PAY NOW
                                        </span>
                                    </Link>
                                </>
                            )}
                        </>
                    ) : (
                        "Pending..."
                    )}
                </td>
                <td className="text-capitalize">{handleStatus(order)}</td>
                <td>{customDate(order.createdAt)}</td>
                <td>
                    <Currency value={order.grandtotal} /> for {formatItem(order).length} item
                    {formatItem(order).length > 1 ? "s" : ""}
                </td>
            </tr>
        ));
        let paginate;
        if (pages > 1) {
            paginate = (
                <>
                    <div className="card-divider" />
                    <div className="card-footer">
                        <Pagination prev={prev} next={next} pages={pages} pageNo={pageNo} setPageNo={setPageNo} />
                    </div>
                </>
            );
        }
        let headerContent;
        let innerContent;

        if (show2) {
            headerContent = (
                <div className="order-header">
                    <div className="order-header__actions">
                        <Link to="/" className="btn btn-xs btn-secondary mr-3" onClick={hideShow2}>
                            <i className="fa fa-arrow-left mr-2"></i>Back to list
                        </Link>
                        <Link to={`/order/${order._id}`} className="btn btn-xs btn-primary">
                            <i className="fa fa-print mr-2"></i>Receipt
                        </Link>
                    </div>
                    <h5 className="order-header__title">Reference No: {order.referenceno}</h5>
                    <div className="order-header__subtitle">
                        Was ordered on <mark className="order-header__date">{customDate(order.createdAt)}.</mark> and is
                        currently <mark className="order-header__status">{handleStatus(order)}</mark>.
                    </div>
                </div>
            );
            let Items;
            if (formatItem(order).length) {
                Items = formatItem(order).map((item) => {
                    let options;
                    if (item.vartype) {
                        options = (
                            <div className="order-list__options">
                                <ul className="order-list__options-list">
                                    <li className="order-list__options-item">
                                        <span className="order-list__options-label">
                                            {item.vartype}
                                            {": "}
                                        </span>
                                        <span className="order-list__options-value">{item.varname}</span>
                                    </li>
                                </ul>
                            </div>
                        );
                    }
                    return (
                        <tr key={item.pvid}>
                            <td className="order-list__column-image">
                                <div className="product-image">
                                    <Link to={url.product(item)} className="product-image__body">
                                        <img
                                            className="product-image__img"
                                            src={
                                                item.image
                                                    ? `${config.apiImageUrl}/small/${item.image}`
                                                    : `${config.apiImageUrl}/small/images/no-image.png`
                                            }
                                            alt=""
                                        />
                                    </Link>
                                </div>
                            </td>
                            <td className="order-list__column-product">
                                <Link to={url.product(item)}>{item.name}</Link>
                                {options}
                            </td>
                            <td className="order-list__column-quantity" data-title="Price:">
                                <Currency value={item.price} />
                            </td>
                            <td className="order-list__column-quantity" data-title=" | Qty:">
                                {item.count}
                            </td>
                            <td className="order-list__column-total">
                                <Currency value={item.amount} />
                            </td>
                        </tr>
                    );
                });
            }
            let additionalLines;
            if (order.vat) {
                additionalLines = (
                    <tr>
                        <th className="order-list__column-label" colSpan="4">
                            Tax
                        </th>
                        <td className="order-list__column-total">
                            <Currency value={order.vat} />
                        </td>
                    </tr>
                );
            }
            innerContent = (
                <div className="order-list">
                    <table>
                        <thead className="order-list__header">
                            <tr>
                                <th className="order-list__column-label" colSpan="2">
                                    Product
                                </th>
                                <th className="order-list__column-quantity">Price</th>
                                <th className="order-list__column-quantity">Qty</th>
                                <th className="order-list__column-total">Total</th>
                            </tr>
                        </thead>
                        <tbody className="order-list__products">{Items}</tbody>
                        <tbody className="order-list__subtotals">
                            <tr>
                                <th className="order-list__column-label" colSpan="4">
                                    Subtotal
                                </th>
                                <td className="order-list__column-total">
                                    <Currency value={order.subtotal} />
                                </td>
                            </tr>
                            {additionalLines}
                        </tbody>
                        <tfoot className="order-list__footer">
                            <tr>
                                <th className="order-list__column-label" colSpan="4">
                                    Grand Total
                                </th>
                                <td className="order-list__column-total">
                                    <Currency value={order.grandtotal} />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            );
        } else {
            headerContent = (
                <div className="card-header">
                    <h5>Order History</h5>
                </div>
            );
            innerContent = (
                <>
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Reference No</th>
                                        {/* <th>Payment</th> */}
                                        <th>Shipping Cost</th>
                                        <th>Order Status</th>
                                        <th>Date</th>
                                        <th>Grand Total</th>
                                    </tr>
                                </thead>
                                <tbody>{ordersList}</tbody>
                            </table>
                        </div>
                    </div>
                    {paginate}
                </>
            );
        }

        content = (
            <>
                {headerContent}
                <div className="card-divider" />
                {innerContent}
            </>
        );
    } else {
        content = <ErrorLoading title={"You don't have any order history to show!"} removeBorder={true} />;
    }

    return (
        <>
            <div className="card">
                <Helmet>
                    <title>{`Order History — ${theme.name}`}</title>
                </Helmet>
                {content}
            </div>
            <div ref={delRef}></div>
            <OrderDetailBottom
                order={order}
                setOrder={setOrder}
                orders={orders}
                setOrders={setOrders}
                user={customer}
                paymentGateway={paymentGateway}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    customer: state.app.customer,
    reload: state.app.reload,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageOrders);
