import { toast } from "react-toastify";

import { LOAD_MENU, SOCIALS, INFO, HIDETOPALERT, CLOSETOPALERT } from "./menuActionTypes";

import { config } from "../../data/custom";

export const fetchMenu = () => {
    const menu = fetch(`${config.apiUrl}/categories/menu`, {
        method: "GET",
        crossDomain: true,
    });
    const info = fetch(`${config.apiUrl}/infos`, {
        method: "GET",
        crossDomain: true,
    });
    const soc = fetch(`${config.apiUrl}/socials`, {
        method: "GET",
        crossDomain: true,
    });
    return (dispatch) => {
        Promise.all([menu, info, soc])
            .then(async ([aa, bb, cc]) => {
                const a = await aa.json();
                const b = await bb.json();
                const c = await cc.json();
                return [a, b, c];
            })
            .then((data) => {
                if (data[0].success === true) {
                    const menus = data[0].data;
                    dispatch(loadMenu(menus));
                } else {
                    toast.error("Menu could not load! Please reload this page.");
                }
                if (data[1].success === true) {
                    dispatch(setInfo(data[1]));
                } else {
                    toast.error("Site info not loaded.");
                }
                if (data[2].success === true) {
                    dispatch(setSocials(data[2].data));
                } else {
                    toast.error("Social link not loaded.");
                }
            })
            .catch((error) => {
                toast.error("Menu and some important information could not load! Please reload this page.");
            });
    };
};
export const loadMenu = (menus) => {
    return {
        type: LOAD_MENU,
        payload: menus,
    };
};
export const setInfo = (info) => {
    return {
        type: INFO,
        payload: info,
    };
};
export const setSocials = (socials) => {
    return {
        type: SOCIALS,
        payload: socials,
    };
};
export const setHideTopAlert = (hideTopAlert) => {
    return {
        type: HIDETOPALERT,
        payload: hideTopAlert,
    };
};
export const setCloseTopAlert = (closeTopAlert) => {
    return {
        type: CLOSETOPALERT,
        payload: closeTopAlert,
    };
};
