// react
import React, { useState } from "react";

// third-party
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

// application
import MobileLinks from "./MobileLinks";
import { Cross20Svg } from "../../svg";
import { mobileMenuClose } from "../../store/mobile-menu";
import { setPriceFilter, setBrandCheck } from "../../store/app";
import mobileMenu from "../../data/mobileMenu";

function MobileMenu(props) {
    const [account, setAccount] = useState(false);
    const { mobileMenuLinks, mobileMenuState, closeMobileMenu, setPriceFilter, setBrandCheck, customer } = props;
    let appendAccount = [
        {
            type: "button",
            label: "Account",
            url: "",
            children: [
                { type: "button", label: "Login", url: "/login" },
                { type: "button", label: "Register", url: "/register" },
                { type: "button", label: "Wishlist", url: "/wishlist" },
            ],
        },
    ];
    if (customer) {
        appendAccount = [
            {
                type: "button",
                label: "Your Account",
                url: "",
                children: [
                    { type: "button", label: "Dashboard", url: "/account/dashboard" },
                    { type: "button", label: "Edit Profile", url: "/account/profile" },
                    { type: "button", label: "Order History", url: "/account/orders" },
                    { type: "button", label: "Factory Request History", url: "/account/factory" },
                    { type: "button", label: "Change Password", url: "/account/change-password" },
                    { type: "button", label: "Wishlist", url: "/wishlist" },
                    { type: "logout", label: "Logout", url: "" },
                ],
            },
        ];
    }
    const classes = classNames("mobilemenu", {
        "mobilemenu--open": mobileMenuState.open,
    });
    let history = useHistory();
    const handleItemClick = (e, to) => {
        setPriceFilter(false);
        setBrandCheck(false);
        closeMobileMenu();
        history.push(to);
    };
    const handleAccount = (boolean) => {
        account === !boolean && setAccount(boolean);
    };
    let display;
    if (account) {
        display = <MobileLinks links={[...appendAccount, ...mobileMenu]} onItemClick={handleItemClick} />;
    } else {
        display = <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} />;
    }
    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">
                        <span onClick={() => handleAccount(false)} className={!account ? " active" : ""}>
                            Menu
                        </span>
                        <span onClick={() => handleAccount(true)} className={account ? " active" : ""}>
                            Account | Info
                        </span>
                    </div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">{display}</div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
    mobileMenuLinks: state.menu.menuMobile,
    customer: state.app.customer.name,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
