import React from "react";
import Carousel from "react-bootstrap/Carousel";
import PropTypes from "prop-types";

const MySlider = ({ sliderPictures }) => {
    let slide = [];
    if (sliderPictures && sliderPictures.length) {
        slide = sliderPictures.map((sl) => {
            let hasHeader = null;
            let hasCaption = null;
            if (sl.header) {
                hasHeader = <h3>{sl.header}</h3>;
            }
            if (sl.caption) {
                hasCaption = <p>{sl.caption}</p>;
            }
            return (
                <Carousel.Item key={sl.key}>
                    <img className="d-block w-100" src={sl.src} alt={sl.altText} />
                    <Carousel.Caption className="d-none d-md-block">
                        {hasHeader}
                        {hasCaption}
                    </Carousel.Caption>
                </Carousel.Item>
            );
        });
    }
    return (
        <Carousel controls={false} indicators={sliderPictures.length > 1 ? true : false}>
            {slide}
        </Carousel>
    );
};
MySlider.propTypes = {
    sliderPictures: PropTypes.array.isRequired,
};
MySlider.defaultProps = {
    sliderPictures: [],
};

export default MySlider;
