import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_CLEAR_ITEMS,
    CART_UPDATE_QUANTITIES,
    CONFIRM_ITEM_SAVED,
    SET_TAX,
    SET_TOREFERENCE,
} from "./cartActionTypes";

export function cartAddItemSuccess(product, quantity = 1) {
    return {
        type: CART_ADD_ITEM,
        product,
        // options,
        quantity,
    };
}

export function cartRemoveItemSuccess(itemId) {
    return {
        type: CART_REMOVE_ITEM,
        itemId,
    };
}

export function cartUpdateQuantitiesSuccess(quantities) {
    return {
        type: CART_UPDATE_QUANTITIES,
        quantities,
    };
}

export function cartAddItem(product, quantity = 1) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartAddItemSuccess(product, quantity));
                resolve();
            }, 500);
        });
}

export function cartRemoveItem(itemId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemoveItemSuccess(itemId));
                resolve();
            }, 500);
        });
}
export function clearCart() {
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch({
                    type: CART_CLEAR_ITEMS,
                });
                resolve();
            }, 500);
        });
}

export function cartUpdateQuantities(quantities) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartUpdateQuantitiesSuccess(quantities));
                resolve();
            }, 500);
        });
}
export function confirmItemSaved(items) {
    return (dispatch) =>
        dispatch({
            type: CONFIRM_ITEM_SAVED,
            items,
        });
}
export function setTax(tax) {
    return (dispatch) =>
        dispatch({
            type: SET_TAX,
            tax,
        });
}
export function setToReference(reference) {
    return (dispatch) =>
        dispatch({
            type: SET_TOREFERENCE,
            reference,
        });
}
