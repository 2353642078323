// react
import React, { useRef, useState, useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";

// application
import PageHeader from "../shared/PageHeader";
import { config, recapta_site_key } from "../../data/custom";
import { setPriceFilter, setBrandCheck } from "../../store/app";
import MessageAlert from "../errors/MessageAlert";

// data stubs
import theme from "../../data/theme";

function AccountForgetPassword({ priceFilter, brandCheck, setPriceFilter, setBrandCheck, customer }) {
    const { handleSubmit, register, errors, reset } = useForm();
    const [alertMsg, setAlertMsg] = useState([]);
    const [load, setLoad] = useState(false);
    const history = useHistory();
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Forget Password", url: "" },
    ];
    const recaptchaRef = useRef(null);
    useEffect(() => {
        if (customer) {
            return history.push("");
        }
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
    }, [customer, brandCheck, priceFilter, setBrandCheck, setPriceFilter, history]);
    const onSubmit = async (values) => {
        setLoad(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setLoad(false);
            return toast.error("No internet connection found!");
        }
        fetch(`${config.apiUrl}/auth/forgotpassword`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    reset();
                    history.push(`/reset-otp/${data.data.resetToken}`);
                } else {
                    setAlertMsg(data.error);
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("No internet connection found!");
                } else {
                    toast.error("An error occured!");
                }
                setLoad(false);
            });
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Forget Password — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <div className="card-title text-center">
                                        <h1 className="pt-lg-0 pt-2">Forget Password</h1>
                                    </div>
                                    <p className="mb-4 pt-2">
                                        Type in your registered phone number below and we'll send you a One Time
                                        Authorization Code(OTP) which you use to create a new password.
                                    </p>
                                    {alertMsg.length ? (
                                        <div className="">
                                            <MessageAlert setAlertMsg={setAlertMsg}>
                                                {alertMsg.map((alertM, index) => (
                                                    <strong key={index}>{alertM}</strong>
                                                ))}{" "}
                                            </MessageAlert>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label htmlFor="track-order-id">
                                                Phone Number <sup className="text-danger"> *</sup>
                                            </label>
                                            <input
                                                id="track-order-id"
                                                autoComplete="off"
                                                name="number"
                                                type="text"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.number,
                                                })}
                                                placeholder="Phone Number"
                                                ref={register({
                                                    required: "Please enter your phone number.",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Phone number is too short",
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message: "Phone number is too long",
                                                    },
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Invalid phone number.",
                                                    },
                                                })}
                                            />
                                            {errors.number && (
                                                <div className="invalid-feedback">{errors.number.message}</div>
                                            )}
                                        </div>
                                        <div className="pt-3">
                                            <button
                                                type="submit"
                                                className={classNames("btn btn-primary btn-lg btn-block", {
                                                    "btn-loading": load,
                                                })}
                                            >
                                                Request OTP Code
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title text-center">Login</h3>
                                    <p>If you have already registered you can login by clicking the button below.</p>
                                    <Link to="/login">
                                        <button type="submit" className="btn btn-block btn-dark mt-2 mt-md-3 mt-lg-4">
                                            Login
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    customer: state.app.customer.name,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountForgetPassword);
