// application
import enMessages from "./messages/en.json";
// import ruMessages from './messages/ru.json';
// import rtlMessages from './messages/rtl.json';

export default {
    en: {
        messages: enMessages,
        direction: "ltr",
    },
    // ru: {
    //     messages: ruMessages,
    //     direction: "ltr",
    // },
    // ar: {
    //     messages: rtlMessages,
    //     direction: "rtl",
    // },
};
