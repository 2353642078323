import React, { useState } from "react";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { usePaystackPayment } from "react-paystack";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";

import { config, getCookie, paystackKey, flutterwaveKey, flutterwaveLogo } from "../../data/custom";
import Currency from "../shared/Currency";
import SuccessModal from "./SuccessModal";
import CancelShippingModal from "./CancelShippingModal";

const OrderDetailBottom = ({ paymentGateway, order, setOrder, orders, setOrders, user }) => {
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [load, setLoad] = useState(false);

    // const payAmount = order.deliveryCost ? parseInt((convFees(order.deliveryCost) + order.deliveryCost) * 100) : 0;
    const payAmount = order.deliveryCost ? parseInt(order.deliveryCost * 100) : 0;
    const paystackConfig = {
        publicKey: paystackKey,
        email: user.email ? user.email : `${user.number}@noemail.com`,
        phone: user.number,
        amount: payAmount,
        currency: "NGN",
        reference: new Date().getTime(),
    };
    const flutterwaveConfig = {
        public_key: flutterwaveKey,
        tx_ref: new Date().getTime(),
        amount: order.deliveryCost,
        currency: "NGN",
        payment_options: "card,mobilemoney,ussd",
        customer: {
            email: user.email ? user.email : `${user.number}@noemail.com`,
            phone_number: user.number,
            name: user.name,
        },
        customizations: {
            title: "Optracoy Payment",
            description: `Payment for delivery`,
            logo: flutterwaveLogo,
        },
    };
    const handlePaystackPayment = usePaystackPayment(paystackConfig);
    const handleFlutterPayment = useFlutterwave(flutterwaveConfig);
    const onSuccess = (response) => {
        // Implementation for whatever you want to do with response and after success call.
        setLoad(true);
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/onlinesales/verifyshippingpayment/${order._id}`, {
            method: "PUT",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
            body: JSON.stringify({
                paymentGateway,
                reference: paymentGateway === "flutterwave" ? response.transaction_id : response.reference,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    setOrder({ ...order, deliveryCostPaid: true });
                    const findInd = orders.findIndex((x) => x._id === order._id);
                    if (findInd !== -1) {
                        const newItems = [
                            ...orders.slice(0, findInd),
                            {
                                ...orders[findInd],
                                deliveryCostPaid: true,
                            },
                            ...orders.slice(findInd + 1),
                        ];
                        setOrders(newItems);
                    }
                } else {
                    toast.error("An Error occured!");
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
                setLoad(false);
            });
    };
    const onClose = () => {};
    const pay4Delivery = () => {
        setLoad(true);
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/onlinesales/reference/${order._id}`, {
            method: "GET",
            crossDomain: true,
            headers: {
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    if (data.data.deliveryCost === order.deliveryCost && !data.data.deliveryCostPaid) {
                        paymentGateway === "paystack" && handlePaystackPayment(onSuccess, onClose);
                        paymentGateway === "flutterwave" &&
                            handleFlutterPayment({
                                callback: (response) => {
                                    onSuccess(response);
                                    closePaymentModal();
                                    // this will close the modal programmatically
                                },
                                onClose: () => {
                                    onClose();
                                },
                            });
                    }
                } else {
                    toast.error("An Error occured!");
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
                setLoad(false);
            });
    };
    const handleStatus = (data) => {
        if (data.delivery) {
            return "Delivered";
        } else if (data.processed) {
            return "Processed";
        } else if (data.processing) {
            return "Processing";
        } else {
            return "Pending";
        }
    };
    const handleShippingInfo = (data) => {
        if (data.delivery) {
            return "This order has been delivered";
        } else if (data.processed) {
            return "This order has been processed";
        } else if (data.processing) {
            return "We are processing your order now. Please be patient. Thanks";
        } else {
            return "We will start processing this order shortly. Please be patient. Thanks";
        }
    };
    const handleShow = () => {
        setShow(true);
    };
    const requestDeliveryMode = (deliveryInfo = {}, deliverytype = "self") => {
        setLoad(true);
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/onlinesales/requestdeliverymode/${order._id}`, {
            method: "PUT",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
            body: JSON.stringify({
                deliveryInfo,
                deliverytype,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    setOrder({ ...order, deliveryInfo: data.data.deliveryInfo, deliverytype: data.data.deliverytype });
                    const findInd = orders.findIndex((x) => x._id === data.data._id);
                    if (findInd !== -1) {
                        const newItems = [
                            ...orders.slice(0, findInd),
                            {
                                ...orders[findInd],
                                deliveryInfo: data.data.deliveryInfo,
                                deliverytype: data.data.deliverytype,
                            },
                            ...orders.slice(findInd + 1),
                        ];
                        setOrders(newItems);
                    }
                } else {
                    toast.error("An Error occured!");
                }
                setLoad(false);
                show && setShow(false);
                show2 && setShow2(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("Internet connection Failure!");
                } else {
                    toast.error("An Error occured!");
                }
                setLoad(false);
                show && setShow(false);
                show2 && setShow2(false);
            });
    };
    const cancelShipping = () => {
        setShow2(true);
    };
    let shipment;
    if (order && order.deliverytype === "shipment") {
        let shipCost;
        if (!order.deliveryCost && !order.deliveryCostPaid) {
            shipCost = (
                <>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Shipping Cost</div>
                        <div className="address-card__row-content">
                            We will calculate your shipping cost and send you a message. You can also check back here or
                            your order history for updates.
                        </div>
                    </div>
                </>
            );
        }
        if (order.deliveryCost && !order.deliveryCostPaid) {
            shipCost = (
                <>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Shipping Cost</div>
                        <div className="address-card__row-content">
                            Your shipping cost is <Currency value={order.deliveryCost} />
                        </div>
                        {/* <div className="address-card__row-content">
                            The convenience fee is <Currency value={convFees(order.deliveryCost)} />
                        </div> */}
                    </div>
                    <div className="address-card__row">
                        <button
                            disabled={load ? true : false}
                            onClick={pay4Delivery}
                            className={classNames("btn btn-sm btn-danger", {
                                "btn-loading": load,
                            })}
                        >
                            Pay Now
                        </button>
                    </div>
                </>
            );
        }
        if (order.deliveryCost && order.deliveryCostPaid) {
            shipCost = (
                <>
                    <div className="address-card__row">
                        <div className="address-card__row-title">Shipping Cost</div>
                        <div className="address-card__row-content">
                            The shipping cost you paid is <Currency value={order.deliveryCost} />
                        </div>
                    </div>
                </>
            );
        }
        shipment = (
            <>
                <div className="col-sm-6 col-12 px-2">
                    <div className="card address-card">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Shipping Address</div>
                            <div className="address-card__name">{order.deliveryInfo.name}</div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Delivery Address</div>
                                <div className="address-card__row-content">{order.deliveryInfo.address}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">{order.deliveryInfo.number}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">City / Town</div>
                                <div className="address-card__row-content">{order.deliveryInfo.city}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">State</div>
                                <div className="address-card__row-content">{order.deliveryInfo.states}</div>
                            </div>
                            {!order.deliveryCostPaid && (
                                <>
                                    <div className="address-card__row text-center">
                                        <div
                                            onClick={cancelShipping}
                                            className={classNames("btn btn-secondary btn-sm under-list", {
                                                "btn-loading": load,
                                            })}
                                        >
                                            Cancel Shipping
                                        </div>
                                    </div>
                                    <CancelShippingModal
                                        show2={show2}
                                        setShow2={setShow2}
                                        load={load}
                                        requestDeliveryMode={requestDeliveryMode}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                    <div className="card address-card">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Response</div>
                            <div className="address-card__name">{handleStatus(order)}</div>
                            <div className="address-card__row">
                                <div className="address-card__row-content">{handleShippingInfo(order)}</div>
                            </div>
                            {shipCost}
                        </div>
                    </div>
                </div>
            </>
        );
    }
    if (order && order.deliverytype === "self") {
        shipment = (
            <>
                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                    <div className="card address-card">
                        {order.delivery ? (
                            <div className="text-center">
                                <div className="addresses-list__item addresses-list__item--new border-0">
                                    <Link to="/" className="btn btn-secondary btn-sm under-list">
                                        Continue Shopping
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="address-card__badge address-card__badge--muted">Request Shipping</div>
                                <div className="text-center">
                                    <div className="addresses-list__item addresses-list__item--new border-0">
                                        <div onClick={handleShow} className="addresses-list__plus under-list" />
                                        <div
                                            onClick={handleShow}
                                            className={classNames("btn btn-secondary btn-sm under-list", {
                                                "btn-loading": load,
                                            })}
                                        >
                                            Request For Shipping
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <SuccessModal
                    show={show}
                    setShow={setShow}
                    load={load}
                    user={user.name}
                    requestDeliveryMode={requestDeliveryMode}
                />
                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                    <div className="card address-card">
                        <div className="address-card__body">
                            <div className="address-card__badge address-card__badge--muted">Response</div>
                            <div className="address-card__name">{handleStatus(order)}</div>
                            <div className="address-card__row">
                                <div className="address-card__row-content">{handleShippingInfo(order)}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
    return <div className="row mt-3 no-gutters mx-n2">{shipment}</div>;
};

export default OrderDetailBottom;
