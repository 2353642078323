import { combineReducers } from "redux";

// reducers
import appReducer from "./app";
import menuReducer from "./menu";
import cartReducer from "./cart";
import factoryReducer from "./factory";
import currencyReducer from "./currency";
import localeReducer from "./locale";
import mobileMenuReducer from "./mobile-menu";
import sidebarReducer from "./sidebar";
import version from "./version";
import wishlistReducer from "./wishlist";

export default combineReducers({
    version: (state = version) => state,
    app: appReducer,
    menu: menuReducer,
    cart: cartReducer,
    factory: factoryReducer,
    currency: currencyReducer,
    locale: localeReducer,
    mobileMenu: mobileMenuReducer,
    sidebar: sidebarReducer,
    wishlist: wishlistReducer,
});
