import React from "react";

// third-party
import { connect } from "react-redux";
import classNames from "classnames";
import { setReload } from "../../store/app";

const ErrorLoading = ({ title, errMsg, reloadNow, isLoading, setReload, removeBorder }) => {
    let err_msg = null;
    if (errMsg) {
        err_msg = errMsg.map((er, index) => (
            <div key={index} className="products-view__empty-subtitle p-4">
                {er}
            </div>
        ));
    }
    const reloadPageNow = () => {
        setReload(Math.random());
    };
    let reloadPage = null;
    if (reloadNow) {
        reloadPage = (
            <div className="text-center">
                <button
                    disabled={isLoading ? true : false}
                    type="button"
                    className={classNames("btn btn-primary", {
                        "btn-loading": isLoading,
                    })}
                    onClick={reloadPageNow}
                >
                    Reload Page
                </button>
            </div>
        );
    }
    return (
        <div className="block">
            <div className="container">
                <div className={`products-view__empty${removeBorder ? " border-0" : ""}`}>
                    <div className="products-view__empty-title">{title ? title : "No product was found!"}</div>
                    {err_msg}
                    {reloadPage}
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    setReload: (value) => setReload(value),
};

export default connect(() => ({}), mapDispatchToProps)(ErrorLoading);
