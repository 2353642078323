import { toast } from "react-toastify";
import {
    CART_ADD_ITEM,
    CART_REMOVE_ITEM,
    CART_CLEAR_ITEMS,
    CART_UPDATE_QUANTITIES,
    CONFIRM_ITEM_SAVED,
    SET_TAX,
    SET_TOREFERENCE,
} from "./cartActionTypes";

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (item.pvid !== product.pvid) {
            return false;
        }

        return true;
    });
}

function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.amount, 0);
}

function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.count, 0);
}

function calcTotal(subtotal, tax) {
    return subtotal + (tax * subtotal) / 100;
}

function addItem(state, product, quantity) {
    const itemIndex = findItemIndex(state.items, product);

    let newItems;
    let err;

    if (itemIndex === -1) {
        if (product.quantity >= quantity) {
            newItems = [
                {
                    pvid: product.pvid,
                    pid: product.pid,
                    name: product.name,
                    image: product.image,
                    type: product.type,
                    varname: product.varname,
                    available: product.quantity,
                    price: product.price,
                    amount: product.price * quantity,
                    count: quantity,
                },
                ...state.items,
            ];
            toast.success(`Product ${product.name} has been added to cart!`);
        } else {
            err = 1;
            toast.error(`Sorry! You can't add more than ${product.quantity}`);
        }
    } else {
        const item = state.items[itemIndex];
        if (item.count + quantity > product.quantity) {
            err = 1;
            product.quantity - item.count === 0
                ? toast.error(`You have added all that is available!`)
                : toast.error(`Sorry! You can only add ${product.quantity - item.count} more`);
        } else {
            newItems = [
                ...state.items.slice(0, itemIndex),
                {
                    ...item,
                    count: item.count + quantity,
                    amount: (item.count + quantity) * item.price,
                },
                ...state.items.slice(itemIndex + 1),
            ];
            toast.success(`Product ${product.name} has been added to cart!`);
        }
    }

    const subtotal = !err && calcSubtotal(newItems);
    const total = !err && calcTotal(subtotal, state.tax);
    if (err) {
        return state;
    }
    localStorage.setItem("cartItems", JSON.stringify(newItems));
    return {
        ...state,
        subtotal,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function removeItem(state, itemId) {
    const { items } = state;
    const newItems = items.filter((item) => item.pvid !== itemId);

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.tax);
    localStorage.setItem("cartItems", JSON.stringify(newItems));
    toast.success("Item has been removed");
    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

function clearAllItem(state) {
    const newItems = [];

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.tax);
    localStorage.setItem("cartItems", JSON.stringify(newItems));
    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

function updateQuantities(state, quantities) {
    let needUpdate = false;

    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.pvid && x.value !== item.count);

        if (!quantity) {
            return item;
        }

        needUpdate = true;

        return {
            ...item,
            count: quantity.value,
            amount: quantity.value * item.price,
        };
    });

    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const total = calcTotal(subtotal, state.tax);
        localStorage.setItem("cartItems", JSON.stringify(newItems));
        toast.success("Your cart has been updated");
        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
        };
    }

    return state;
}
function confirmItem(state, items) {
    const subtotal = calcSubtotal(items);
    const total = calcTotal(subtotal, state.tax);
    localStorage.setItem("cartItems", JSON.stringify(items));
    return {
        ...state,
        items,
        quantity: calcQuantity(items),
        subtotal,
        total,
    };
}
const initialState = {
    quantity: 0,
    items: [],
    subtotal: 0,
    tax: 0,
    // extraLines: [
    //     {
    //         type: "tax",
    //         title: "Tax",
    //         price: 0,
    //     },
    // ],
    total: 0,
    toreference: "",
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CART_ADD_ITEM:
            return addItem(state, action.product, action.quantity);

        case CART_REMOVE_ITEM:
            return removeItem(state, action.itemId);

        case CART_CLEAR_ITEMS:
            return clearAllItem(state);

        case CART_UPDATE_QUANTITIES:
            return updateQuantities(state, action.quantities);

        case CONFIRM_ITEM_SAVED:
            return confirmItem(state, action.items);

        case SET_TAX:
            return { ...state, tax: action.tax, total: (state.subtotal * action.tax) / 100 + state.subtotal };

        case SET_TOREFERENCE:
            return { ...state, toreference: action.reference };
        default:
            return state;
    }
}
