// react
import React, { useEffect } from "react";

// third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import parse from "html-react-parser";

// application
import PageHeader from "../shared/PageHeader";
import { setPriceFilter, setBrandCheck } from "../../store/app";
import ErrorLoading from "../errors/ErrorLoading";

// data stubs
import theme from "../../data/theme";

const SitePageAboutUs = ({ brandCheck, priceFilter, setPriceFilter, setBrandCheck, reload, info }) => {
    useEffect(() => {
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
    }, [reload, priceFilter, brandCheck, setBrandCheck, setPriceFilter, info]);
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "About Us", url: "" },
    ];
    let content;
    if (info && info.about) {
        content = (
            <div className="document">
                <div className="document__header">
                    <h1 className="document__title">About Us</h1>
                </div>
                <div className="document__content typography">{parse(parse(info.about))}</div>
            </div>
        );
    } else {
        content = (
            <ErrorLoading
                title={"You can not proceed with your request!"}
                errMsg={["About page not found"]}
                reloadNow={true}
                //isLoading={isLoading}
            />
        );
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`About Us — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">{content}</div>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    info: state.menu.info,
    reload: state.app.reload,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(SitePageAboutUs);
