import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import classNames from "classnames";
import parse from "html-react-parser";
import ReactGA from "react-ga";
import ReCAPTCHA from "react-google-recaptcha";

import InputNumber from "../shared/InputNumber";
import AsyncAction from "../shared/AsyncAction";
import FactoryModal from "./FactoryModal";
import YoutubeRequestModal from "./YoutubeRequestModal";
import MessageAlert from "../errors/MessageAlert";

import PageHeader from "../shared/PageHeader";
import ErrorLoading from "../errors/ErrorLoading";
import { setPriceFilter, setBrandCheck } from "../../store/app";
import { addRowItem, editRowItem, removeRowItem, clearRows, setMaterialColor, setJobDesc } from "../../store/factory";
import { config, getCookie, limitStr, randomId, youtubeUrl, recapta_site_key, GATrackingId } from "../../data/custom";

import theme from "../../data/theme";
import { Cross12Svg, Edit12Svg, Play30Svg } from "../../svg";
const initialAdd = {
    len: 1,
    wid: 1,
    qty: 1,
    eLen: 0,
    eWid: 0,
    inst: "",
};
const EnterFactory = ({
    customer,
    priceFilter,
    brandCheck,
    setBrandCheck,
    setPriceFilter,
    reload,
    factory,
    info,
    addRowItem,
    editRowItem,
    removeRowItem,
    clearRows,
    setMaterialColor,
    setJobDesc,
}) => {
    const cutRef = useRef(null);
    const recaptchaRef = useRef(null);
    const [addVal, setAddVal] = useState(initialAdd);
    const [edit, setEdit] = useState("");
    const [rowNo, setRowNo] = useState(null);
    const [load, setLoad] = useState(false);
    const [modal, setModal] = useState(false);
    const [show, setShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState([]);
    const [errMsg, seterrMsg] = useState([]);
    const [success, setSuccess] = useState("");
    let history = useHistory();
    const { handleSubmit, register, errors, reset } = useForm();
    useEffect(() => {
        seterrMsg([]);
        setAlertMsg([]);
        setSuccess("");
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        const cookiesN = getCookie("optc_u_s_r");
        if (!customer && !cookiesN) {
            return history.push("login?return=factory");
        }
        GATrackingId && ReactGA.initialize(GATrackingId);
        GATrackingId && ReactGA.pageview(window.location.pathname);
    }, [reload, priceFilter, brandCheck, setBrandCheck, setPriceFilter, customer, history]);
    const onSubmit = async (values) => {
        seterrMsg([]);
        setAlertMsg([]);
        setSuccess("");
        setLoad(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setLoad(false);
            return toast.error("No internet connection found!");
        }
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/factories`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
            body: JSON.stringify({
                jobdescription: values.jobDesc,
                materialcolor: values.materialColor,
                pattern: values.pattern,
                measurement: JSON.parse(values.measurement),
                recaptchaValue: values.recaptchaValue,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    setJobDesc("");
                    setMaterialColor("");
                    clearRows();
                    setSuccess(data.data);
                    reset();
                } else {
                    setAlertMsg(data.error);
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["No internet connection found!"]);
                } else {
                    toast.error("An error occured!");
                }
                setLoad(false);
            });
    };
    const openModal = (req, rowNo) => {
        setRowNo(rowNo);
        setEdit(req);
        setModal(true);
    };
    const addIntoTable = () => {
        addRowItem({
            id: randomId(4),
            len: addVal.len,
            wid: addVal.wid,
            qty: addVal.qty,
            eLen: addVal.eLen,
            eWid: addVal.eWid,
            inst: addVal.inst,
        });
        setAddVal(initialAdd);
        if (errors.measurement) {
            errors.measurement.message = "";
        }
    };
    const closeSuccess = () => {
        setSuccess("");
    };
    const toList = () => {
        cutRef.current.scrollIntoView({ behavior: "smooth" });
    };
    const toYoutube = () => {
        setShow(true);
    };
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Factory Request", url: "" },
    ];
    let content;
    if (errMsg && errMsg.length) {
        content = (
            <ErrorLoading
                title={"You can not proceed with your request!"}
                errMsg={errMsg}
                reloadNow={true}
                //isLoading={isLoading}
            />
        );
    } else {
        let specReq;
        if (factory.requestRows && factory.requestRows.length) {
            specReq = factory.requestRows.map((req, index) => {
                const editButton = (
                    <button
                        type="button"
                        onClick={() => openModal(req, index + 1)}
                        className="btn btn-outline-dark btn-sm btn-svg-icon mr-0 mr-lg-2 mb-2 mb-sm-0 mb-lg-0"
                    >
                        <Edit12Svg />
                    </button>
                );
                const removeButton = (
                    <AsyncAction
                        action={() => removeRowItem(req.id)}
                        render={({ run, loading }) => {
                            const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                                "btn-loading": loading,
                            });

                            return (
                                <button type="button" onClick={run} className={classes}>
                                    <Cross12Svg />
                                </button>
                            );
                        }}
                    />
                );
                return (
                    <tr key={req.id}>
                        <td>{index + 1}</td>
                        <td>{req.len}</td>
                        <td>{req.wid}</td>
                        <td>{req.qty}</td>
                        <td>{req.eLen}</td>
                        <td>{req.eWid}</td>
                        <td>{limitStr(req.inst, 25)}</td>
                        <td className="text-center">
                            {editButton}
                            {removeButton}
                        </td>
                    </tr>
                );
            });
        } else {
            specReq = (
                <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            );
        }
        const showReq = (
            <div className="card">
                <div className="card-table">
                    <div className="table-responsive-md">
                        <table>
                            <thead>
                                <tr>
                                    <th>S/No</th>
                                    <th>Length in MM</th>
                                    <th>Width in MM</th>
                                    <th>QTY.</th>
                                    <th>Edge Length</th>
                                    <th>Edge Width</th>
                                    <th>Special Instructions</th>
                                    <th style={{ width: "120px" }}>Action</th>
                                </tr>
                            </thead>
                            <tbody className="card-table__body card-table__body--merge-rows">{specReq}</tbody>
                        </table>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="mb-2">
                        <label>
                            <strong>
                                Fill the measurement input below and add into the above table
                                <sup className="text-danger"> *</sup>
                            </strong>
                        </label>
                    </div>
                    <div className="form-row">
                        <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-lg-0 mb-sm-2">
                            <label htmlFor="len">Length in MM</label>
                            <InputNumber
                                id="len"
                                aria-label="len"
                                className=""
                                //disabled={!addProduct.quantity}
                                size="sm"
                                min={1}
                                value={addVal.len}
                                onChange={(len) => setAddVal({ ...addVal, len })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-lg-0 mb-sm-2">
                            <label htmlFor="wid">Width in MM</label>
                            <InputNumber
                                id="wid"
                                aria-label="wid"
                                className=""
                                //disabled={!addProduct.quantity}
                                size="sm"
                                min={1}
                                value={addVal.wid}
                                onChange={(wid) => setAddVal({ ...addVal, wid })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-lg-0 mb-sm-2">
                            <label htmlFor="qty">Quantity</label>
                            <InputNumber
                                id="qty"
                                aria-label="qty"
                                className=""
                                //disabled={!addProduct.quantity}
                                size="sm"
                                min={1}
                                value={addVal.qty}
                                onChange={(qty) => setAddVal({ ...addVal, qty })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-lg-0 mb-sm-2">
                            <label htmlFor="eLen">Edge Length in MM</label>
                            <InputNumber
                                id="eLen"
                                aria-label="eLen"
                                className=""
                                //disabled={!addProduct.quantity}
                                size="sm"
                                min={0}
                                value={addVal.eLen}
                                onChange={(eLen) => setAddVal({ ...addVal, eLen })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-lg-0 mb-sm-2">
                            <label htmlFor="eWid">Edge Width in MM</label>
                            <InputNumber
                                id="eWid"
                                aria-label="eWid"
                                className=""
                                //disabled={!addProduct.quantity}
                                size="sm"
                                min={0}
                                value={addVal.eWid}
                                onChange={(eWid) => setAddVal({ ...addVal, eWid })}
                            />
                        </div>
                        <div className="col-6 col-sm-6 col-md-4 col-lg-2 mb-lg-0 mb-sm-2">
                            <label htmlFor="inst">Special Instructions</label>
                            <input
                                type="text"
                                id="inst"
                                aria-label="inst"
                                className="form-control form-control-sm"
                                //disabled={!addProduct.quantity}
                                value={addVal.inst}
                                onChange={(e) => setAddVal({ ...addVal, inst: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="mt-4">
                        <button onClick={addIntoTable} type="button" className="btn btn-dark">
                            Add Measurement
                        </button>
                    </div>
                </div>
            </div>
        );
        let youtubeView;
        if (youtubeUrl) {
            youtubeView = (
                <>
                    <div className="block-banner__title">
                        Watch
                        <span>
                            {" "}
                            <br className="block-banner__mobile-br" />
                            Factory Video
                        </span>
                    </div>
                    <div className="block-banner__button">
                        <button className="play-btn" onClick={toYoutube}>
                            <Play30Svg />
                        </button>
                    </div>
                </>
            );
        }

        content = (
            <div className="block">
                <div className="container">
                    <div className="block block-banner">
                        <div className="block-banner__body2">
                            <div
                                className="block-banner__image block-banner__image--desktop"
                                style={{ backgroundImage: 'url("images/banners/factory.jpg")' }}
                            />
                            <div
                                className="block-banner__image block-banner__image--mobile"
                                style={{ backgroundImage: 'url("images/banners/factory-mobile.jpg")' }}
                            />
                            {youtubeView}
                            <div className={`block-banner__button${youtubeUrl ? "" : " block-banner__ytb"}`}>
                                <span className="btn btn-sm btn-primary" onClick={toList}>
                                    Send Cutting List
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="mb-5 factory typography">
                        <div className="block-banner__title pt-0 mb-4 text-center">Our Cutting Factory</div>
                        {info.factory ? parse(parse(info.factory)) : "-"}
                    </div>
                    <div ref={cutRef}>
                        <div className="block-header">
                            <h3 className="block-header__title">Cutting List Area</h3>
                            <div className="block-header__divider"></div>
                        </div>
                        <div className="lead mb-3">
                            Please ensure you fill all the require fields of the the form before submitting. Thanks.
                        </div>
                        {alertMsg.length ? (
                            <div className="">
                                <MessageAlert setAlertMsg={setAlertMsg}>
                                    {alertMsg.map((alertM, index) => (
                                        <strong key={index}>{alertM}</strong>
                                    ))}{" "}
                                </MessageAlert>
                            </div>
                        ) : (
                            ""
                        )}
                        {success ? (
                            <div className="alert alert-primary alert-lg">
                                <strong>
                                    Your request has been received and the reference no is {success}. We will reach out
                                    to you on the cost and you can track our response at{" "}
                                    <Link to="/account/factory">Factory Request History</Link>
                                </strong>
                                <button type="button" className="close" onClick={closeSuccess}>
                                    <span>&times;</span>
                                </button>
                            </div>
                        ) : (
                            ""
                        )}
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-row">
                                <div className="form-group col-md-4 mb-lg-0 mb-sm-2">
                                    <label htmlFor="jobDesc">Job Description</label>
                                    <input
                                        autoComplete="off"
                                        name="jobDesc"
                                        id="jobDesc"
                                        type="text"
                                        className="form-control"
                                        placeholder="Job description"
                                        defaultValue={factory.jobDesc}
                                        onChange={(e) => setJobDesc(e.target.value)}
                                        ref={register({
                                            maxLength: {
                                                value: 100,
                                                message: "Job description should not be more than 100 characters",
                                            },
                                        })}
                                    />
                                    {errors.jobDesc && <p className="text-danger">{errors.jobDesc.message}</p>}
                                </div>
                                <div className="form-group col-md-4 mb-lg-0 mb-sm-2">
                                    <label htmlFor="materialColor">
                                        Material Colour<sup className="text-danger"> *</sup>
                                    </label>
                                    <input
                                        autoComplete="off"
                                        name="materialColor"
                                        id="materialColor"
                                        type="text"
                                        className="form-control"
                                        placeholder="Material Colour"
                                        defaultValue={factory.materialColor}
                                        onChange={(e) => setMaterialColor(e.target.value)}
                                        ref={register({
                                            required: "Please enter material colour",
                                            maxLength: {
                                                value: 50,
                                                message: "Material Colour should not be more than 50 characters",
                                            },
                                        })}
                                    />
                                    <input
                                        type="hidden"
                                        name="measurement"
                                        value={
                                            factory.requestRows && factory.requestRows.length
                                                ? JSON.stringify(factory.requestRows)
                                                : ""
                                        }
                                        ref={register({
                                            required: "Please add your measurements",
                                        })}
                                    />
                                    {errors.materialColor && (
                                        <p className="text-danger">{errors.materialColor.message}</p>
                                    )}
                                </div>
                                <div className="form-group col-md-4 mb-lg-0 mb-sm-2">
                                    <label>
                                        Pattern<sup className="text-danger"> *</sup>
                                    </label>
                                    <div className="p-2">
                                        <div className="custom-control custom-radio custom-control-inline mr-5">
                                            <input
                                                type="radio"
                                                id="matte"
                                                name="pattern"
                                                className="custom-control-input"
                                                value="matte"
                                                ref={register({
                                                    required: "Please select either matte or wood grain",
                                                })}
                                            />
                                            <label className="custom-control-label" htmlFor="matte">
                                                Matte (Plain)
                                            </label>
                                        </div>
                                        <div className="custom-control custom-radio custom-control-inline">
                                            <input
                                                type="radio"
                                                id="grain"
                                                name="pattern"
                                                className="custom-control-input"
                                                value="grain"
                                                ref={register({
                                                    required: "Please select either matte or wood grain",
                                                })}
                                            />
                                            <label className="custom-control-label" htmlFor="grain">
                                                Wood Grain
                                            </label>
                                        </div>
                                    </div>
                                    {errors.pattern && <p className="text-danger">{errors.pattern.message}</p>}
                                </div>
                            </div>
                            <div className="mt-3">
                                <label>
                                    Factory Cutting Request Table<sup className="text-danger"> *</sup>
                                </label>
                            </div>
                            {showReq}
                            {errors.measurement && <p className="text-danger">{errors.measurement.message}</p>}

                            <FactoryModal
                                modal={modal}
                                setModal={setModal}
                                edit={edit}
                                editRowItem={editRowItem}
                                rowNo={rowNo}
                            />
                            <YoutubeRequestModal show={show} setShow={setShow} youtubeUrl={youtubeUrl} />
                            <div className="">
                                <button
                                    disabled={load ? true : false}
                                    type="submit"
                                    className={classNames("btn btn-primary mt-4", {
                                        "btn-loading": load,
                                    })}
                                >
                                    Send Request
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Factory Request — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Factory Request" breadcrumb={breadcrumb} />
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
            {content}
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    customer: state.app.customer.name,
    reload: state.app.reload,
    info: state.menu.info,
    factory: state.factory,
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
    addRowItem: (boolean) => addRowItem(boolean),
    editRowItem: (value, no) => editRowItem(value, no),
    removeRowItem: (value) => removeRowItem(value),
    clearRows: () => clearRows(),
    setMaterialColor: (value) => setMaterialColor(value),
    setJobDesc: (value) => setJobDesc(value),
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterFactory);
