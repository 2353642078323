// react
import React from "react";

// third-party
import PropTypes from "prop-types";

// application
import { Fi24Hours48Svg, FiFreeDelivery48Svg, FiPaymentSecurity48Svg, FiTag48Svg } from "../../svg";

export default function BlockFeatures(props) {
    const { layout } = props;

    return (
        <div className={`block block-features block-features--layout--${layout}`}>
            <div className="container">
                <div className="block-features__list">
                    <div className="block-features__item hide-feature">
                        <div className="block-features__icon">
                            <FiFreeDelivery48Svg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Nationwide Shipping</div>
                            <div className="block-features__subtitle">On all orders</div>
                        </div>
                    </div>
                    <div className="block-features__divider hide-feature" />
                    <div className="block-features__item hide-feature">
                        <div className="block-features__icon">
                            <Fi24Hours48Svg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Support</div>
                            <div className="block-features__subtitle">Call us</div>
                        </div>
                    </div>
                    <div className="block-features__divider hide-feature" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <FiPaymentSecurity48Svg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">100% Safety</div>
                            <div className="block-features__subtitle">Paystack secure payments</div>
                        </div>
                    </div>
                    <div className="block-features__divider" />
                    <div className="block-features__item">
                        <div className="block-features__icon">
                            <FiTag48Svg />
                        </div>
                        <div className="block-features__content">
                            <div className="block-features__title">Sale Offers</div>
                            <div className="block-features__subtitle">Discounts up to 90%</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(["classic", "boxed"]),
};

BlockFeatures.defaultProps = {
    layout: "classic",
};
