// react
import React from "react";

// third-party
import classNames from "classnames";

// application
import { ArrowRoundedLeft8x13Svg, ArrowRoundedRight8x13Svg } from "../../svg";
function Pagination({ pages, pageNo, setPageNo, pageLimit = 5 }) {
    function goToNextPage() {
        setPageNo((pageNo) => pageNo + 1);
    }
    function goToPreviousPage() {
        setPageNo((pageNo) => pageNo - 1);
    }
    const changePage = (i) => {
        setPageNo(i);
    };
    const getPaginationGroup = () => {
        let start = Math.floor((pageNo - 1) / pageLimit) * pageLimit;
        return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
    };
    let inner = [];

    getPaginationGroup().map((i) => {
        const classes = classNames("page-item", {
            active: i === pageNo,
        });
        if (i <= pages) {
            inner.push(
                <li key={i} className={classes}>
                    <button type="button" className="page-link" onClick={(e) => changePage(i)}>
                        {i}
                        {i === pageNo && <span className="sr-only">(current)</span>}
                    </button>
                </li>
            );
        }
        return i;
    });

    const firstLinkClasses = classNames("page-item", {
        disabled: pageNo === 1,
    });
    const lastLinkClasses = classNames("page-item", {
        disabled: pageNo === pages,
    });
    return (
        <nav>
            <ul className="pagination justify-content-center">
                <li className={firstLinkClasses}>
                    <button
                        type="button"
                        className="page-link page-link--with-arrow"
                        aria-label="Previous"
                        onClick={(e) => changePage(goToPreviousPage)}
                    >
                        <ArrowRoundedLeft8x13Svg
                            className="page-link__arrow page-link__arrow--left"
                            aria-hidden="true"
                        />
                    </button>
                </li>
                {inner}
                <li className={lastLinkClasses}>
                    <button
                        type="button"
                        className="page-link page-link--with-arrow"
                        aria-label="Next"
                        onClick={(e) => changePage(goToNextPage)}
                    >
                        <ArrowRoundedRight8x13Svg
                            className="page-link__arrow page-link__arrow--right"
                            aria-hidden="true"
                        />
                    </button>
                </li>
            </ul>
        </nav>
    );
}

// function Pagination({ prev, next, pages, pageNo, setPageNo }) {
//     const changePage = (i) => {
//         setPageNo(i);
//     };
//     let inner = [];
//     if (pages < 6) {
//         for (let i = 1; i <= pages; i++) {
//             const classes = classNames("page-item", {
//                 active: i === pageNo,
//             });

//             inner.push(
//                 <li key={i} className={classes}>
//                     <button type="button" className="page-link" onClick={(e) => changePage(i)}>
//                         {i}
//                         {i === pageNo && <span className="sr-only">(current)</span>}
//                     </button>
//                 </li>
//             );
//         }
//     } else {
//         inner.push(
//             <li className="page-item active">
//                 <button type="button" className="page-link" onClick={(e) => changePage(pageNo)}>
//                     {pageNo}
//                 </button>
//             </li>
//         );
//     }

//     const Previous = prev;
//     const Next = next;
//     const firstLinkClasses = classNames("page-item", {
//         disabled: Previous === 0,
//     });
//     const lastLinkClasses = classNames("page-item", {
//         disabled: Next === 0,
//     });
//     return (
//         <nav>
//             <ul className="pagination justify-content-center">
//                 <li className={firstLinkClasses}>
//                     <button
//                         type="button"
//                         className="page-link page-link--with-arrow"
//                         aria-label="Previous"
//                         onClick={(e) => changePage(Previous)}
//                     >
//                         <ArrowRoundedLeft8x13Svg
//                             className="page-link__arrow page-link__arrow--left"
//                             aria-hidden="true"
//                         />
//                     </button>
//                 </li>
//                 {inner}
//                 <li className={lastLinkClasses}>
//                     <button
//                         type="button"
//                         className="page-link page-link--with-arrow"
//                         aria-label="Next"
//                         onClick={(e) => changePage(Next)}
//                     >
//                         <ArrowRoundedRight8x13Svg
//                             className="page-link__arrow page-link__arrow--right"
//                             aria-hidden="true"
//                         />
//                     </button>
//                 </li>
//             </ul>
//         </nav>
//     );
// }
export default Pagination;
