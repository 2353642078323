export default [
    {
        type: "button",
        label: "Information",
        url: "",
        children: [
            { type: "button", label: "About Us", url: "/about-us" },
            { type: "button", label: "Terms And Conditions", url: "/terms" },
            { type: "button", label: "Privacy Policy", url: "/privacy-policy" },
        ],
    },
    {
        type: "button",
        label: "Factory Request",
        url: "/factory",
        // children: [
        //     { type: "button", label: "Enter Factory", url: "/factory" },
        //     { type: "button", label: "Send Request", url: "/factory" },
        // ],
    },
];
