// react
import React, { useCallback } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";

// application
import Collapse from "../shared/Collapse";
import FilterRange from "../filters/FilterRange";
import getFilterHandler from "../../services/filters";
import { ArrowRoundedDown12x7Svg, Check9x7Svg } from "../../svg";

const WidgetFilters = ({
    dispatch,
    min,
    max,
    minValue,
    maxValue,
    priceFilter,
    setPriceFilter,
    brand,
    brandCheck,
    setBrandCheck,
    isLoading,
    setReload,
    title,
    offcanvas,
    onlineIncrement,
}) => {
    const handleValueChange = useCallback(
        ({ filter, value }) => {
            const handler = getFilterHandler(filter);
            if (handler) {
                dispatch({
                    type: "SET_FILTER_VALUE",
                    filter: filter.slug,
                    value: handler.serialize(value),
                });
            }
        },
        [dispatch]
    );

    const handleResetFilters = () => {
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        dispatch({ type: "RESET_FILTERS" });
        setReload(Math.random());
    };
    const handleChangePrice = () => {
        !priceFilter && setPriceFilter(true);
        setReload(Math.random());
    };
    const toggleChecked = (e, id) => {
        const brd = brand.map((brand) => {
            if (brand.id === id) {
                brand.checked = e.target.checked;
            }
            return brand;
        });
        !brandCheck && setBrandCheck(true);
        dispatch({ type: "SET_BRAND", payload: brd });
        setReload(Math.random());
    };

    const loadPrice =
        minValue < maxValue ? (
            <div className="widget-filters__item">
                <Collapse
                    toggleClass="filter--opened"
                    render={({ toggle, setItemRef, setContentRef }) => (
                        <div className="filter filter--opened" ref={setItemRef}>
                            <button type="button" className="filter__title" onClick={toggle}>
                                Price
                                <ArrowRoundedDown12x7Svg className="filter__arrow" />
                            </button>
                            <div className="filter__body" ref={setContentRef}>
                                <div className="filter__container">
                                    <FilterRange
                                        data={{
                                            max: maxValue,
                                            min: minValue,
                                            name: "Price",
                                            slug: "price",
                                            type: "range",
                                        }}
                                        value={[parseInt(min), parseInt(max)]}
                                        onChangeValue={handleValueChange}
                                        handleChangePrice={handleChangePrice}
                                        onlineIncrement={onlineIncrement}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                />
            </div>
        ) : null;
    const allBrands = brand.map((brand) => {
        return (
            <label key={brand.id} className="filter-list__item">
                <span className="filter-list__input input-check">
                    <span className="input-check__body">
                        <input
                            className="input-check__input"
                            type="checkbox"
                            checked={brand.checked}
                            onChange={(e) => toggleChecked(e, brand.id)}
                        />
                        <span className="input-check__box" />
                        <Check9x7Svg className="input-check__icon" />
                    </span>
                </span>
                <span className="filter-list__title">{brand.name}</span>
                {brand.count}
            </label>
        );
    });
    const loadBrands =
        brand && brand.length ? (
            <div className="widget-filters__item">
                <Collapse
                    toggleClass="filter--opened"
                    render={({ toggle, setItemRef, setContentRef }) => (
                        <div className="filter filter--opened" ref={setItemRef}>
                            <button type="button" className="filter__title" onClick={toggle}>
                                Brands
                                <ArrowRoundedDown12x7Svg className="filter__arrow" />
                            </button>
                            <div className="filter__body" ref={setContentRef}>
                                <div className="filter__container">
                                    <div className="filter-list">
                                        <div className="filter-list__list">{allBrands}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                />
            </div>
        ) : null;

    const classes = classNames("widget-filters widget", {
        "widget-filters--offcanvas--always": offcanvas === "always",
        "widget-filters--offcanvas--mobile": offcanvas === "mobile",
    });

    return (
        <div className={classes}>
            <h4 className="widget-filters__title widget__title">{title}</h4>

            <div className="widget-filters__list">
                {loadPrice}
                {loadBrands}
            </div>

            <div className="widget-filters__actions d-flex mb-n2">
                <button
                    disabled={isLoading ? true : false}
                    type="button"
                    className="btn btn-secondary btn-sm"
                    onClick={handleResetFilters}
                >
                    Reset
                </button>
            </div>
        </div>
    );
};

WidgetFilters.propTypes = {
    /**
     * widget title
     */
    title: PropTypes.node,
    /**
     * Category page dispatcher.
     */
    dispatch: PropTypes.func,
    /**
     * Products list filters.
     */
    //filters: PropTypes.array,
    /**
     * Products list filter values.
     */
    values: PropTypes.object,
    /**
     * indicates when sidebar bar should be off canvas
     */
    offcanvas: PropTypes.oneOf(["always", "mobile"]),
};

WidgetFilters.defaultProps = {
    offcanvas: "mobile",
};

export default WidgetFilters;
