import React from "react";

// application
import Currency from "../shared/Currency";
import { config } from "../../data/custom";
import logo from "../../img/logo.png";

class Print extends React.Component {
    render() {
        return (
            <div className="invoice overflow-auto">
                <div style={{ minWidth: "900px" }}>
                    <header>
                        <div className="row">
                            <div className="col">
                                <img src={logo} alt="..." />
                            </div>
                            <div className="col company-details">
                                <div>{this.props.info.webcontactaddress}</div>
                                <div>{this.props.info.webemail}</div>
                                <div>{this.props.info.webphone}</div>
                            </div>
                        </div>
                    </header>
                    <main>
                        <div className="row contacts">
                            <div className="col invoice-to">
                                <div className="text-gray-light">RECEIPT ISSUED TO:</div>
                                <h2 className="to text-capitalize">{this.props.sale.customername}</h2>
                                <div className="address">{this.props.user.email}</div>
                                <div className="email">{this.props.user.number}</div>
                            </div>
                            <div className="col invoice-details">
                                <div className="date">REFERENCE NUMBER</div>
                                <h1 className="invoice-id">{this.props.sale.referenceno}</h1>
                                <div className="date">Date: {this.props.customDate(this.props.sale.createdAt)}</div>
                            </div>
                        </div>
                        <table border="0" cellSpacing="0" cellPadding="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th className="pics">PICTURE</th>
                                    <th className="text-left">PRODUCT DESCRIPTION</th>
                                    <th className="text-right">UNIT PRICE</th>
                                    <th className="text-right">QUANTITY</th>
                                    <th className="text-right">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.cart.map((cart, index) => (
                                    <tr key={cart.pvid}>
                                        <td className="no">{index + 1}</td>
                                        <td className="">
                                            <img
                                                src={
                                                    cart.image
                                                        ? `${config.apiImageUrl}/small/${cart.image}`
                                                        : `${config.apiImageUrl}/small/images/no-image.png`
                                                }
                                                alt="..."
                                                title="Product Picture"
                                                width="80px"
                                            />
                                        </td>
                                        <td className="text-left">
                                            <h3 className="text-capitalize">{cart.name}</h3>
                                            {cart.vartype && (
                                                <p>
                                                    <strong>{cart.vartype} : </strong>
                                                    {cart.varname}
                                                </p>
                                            )}
                                        </td>
                                        <td className="unit">{<Currency value={cart.price} />}</td>
                                        <td className="qty">{cart.count}</td>
                                        <td className="total">{<Currency value={cart.amount} />}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="3"></td>
                                    <td colSpan="2">SUB TOTAL :</td>
                                    <td className="printd pr-1">{<Currency value={this.props.sale.subtotal} />}</td>
                                </tr>

                                <tr>
                                    <td colSpan="3"></td>
                                    <td colSpan="2">TAX :</td>
                                    <td className="printd pr-1">
                                        {this.props.sale.vat > 0 ? <Currency value={this.props.sale.vat} /> : "-"}
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="3"></td>
                                    <td colSpan="2">GRAND TOTAL :</td>
                                    <td className="pr-1">{<Currency value={this.props.sale.grandtotal} />}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div className="thanks">Thank you!</div>
                        <div className="notices">
                            <div>NOTICE:</div>
                            <div className="notice">
                                This receipt is your only reference to the products you bought. Don't misplace it.
                            </div>
                        </div>
                    </main>
                    <footer>Receipt was created on a computer and is valid without the signature and seal.</footer>
                </div>

                {/* <div></div> */}
            </div>
        );
    }
}

export default Print;
