// react
import React, { useState, useEffect } from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import ReactGA from "react-ga";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import InputNumber from "../shared/InputNumber";
import PageHeader from "../shared/PageHeader";
import ErrorLoading from "../errors/ErrorLoading";
import { cartRemoveItem, cartUpdateQuantities, clearCart, setTax } from "../../store/cart";
import { setPriceFilter, setBrandCheck } from "../../store/app";
import { Cross12Svg } from "../../svg";
import { setHideTopAlert } from "../../store/menu";

// data stubs
import theme from "../../data/theme";
import { config, GATrackingId } from "../../data/custom";

// blocks
import BlockLoader from "../blocks/BlockLoader";

const ShopPageCart = ({
    cart,
    cartRemoveItem,
    cartUpdateQuantities,
    setTax,
    clearCart,
    priceFilter,
    brandCheck,
    setPriceFilter,
    setBrandCheck,
    reload,
    setHideTopAlert,
}) => {
    const [quantities, setQuantities] = useState([]);
    const [taxerr, setTaxerr] = useState([]);
    const [allow, setAllow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let history = useHistory();
    useEffect(() => {
        setTaxerr([]);
        setHideTopAlert(false);
        if (!cart.tax) {
            setIsLoading(true);
            fetch(`${config.apiUrl}/infos?select=tax,allowOnlinePayment`, {
                method: "GET",
                crossDomain: true,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success === true) {
                        setTax(data.data[0].tax);
                        setAllow(data.data[0].allowOnlinePayment);
                        GATrackingId && ReactGA.initialize(GATrackingId);
                        GATrackingId && ReactGA.pageview(window.location.pathname);
                    } else {
                        setTaxerr(["An error occured!"]);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    if (error.message === "Failed to fetch") {
                        setTaxerr(["Please check your internet connection and reload the page"]);
                    } else {
                        setTaxerr(["An error occured!"]);
                    }
                    setIsLoading(false);
                });
        }
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        // eslint-disable-next-line
    }, [reload, cart.tax, setTax, priceFilter, brandCheck, setPriceFilter, setBrandCheck]);
    if (isLoading) {
        return <BlockLoader />;
    }
    const goToCheckout = () => {
        history.push("/checkout");
    };
    const getItemQuantity = (item) => {
        const quantity = quantities.find((x) => x.itemId === item.pvid);

        return quantity ? quantity.value : item.count;
    };
    const handleChangeQuantity = (item, quantity) => {
        const stateQuantity = quantities.find((x) => x.itemId === item.pvid);
        if (!quantity) {
            cartRemoveItem(item.pvid);
        }

        if (!stateQuantity) {
            if (item.available >= quantity) {
                setQuantities([...quantities, { itemId: item.pvid, value: quantity }]);
            }
        } else {
            if (item.available >= quantity) {
                setQuantities(
                    quantities.map((x) => {
                        if (x.itemId === stateQuantity.itemId) {
                            stateQuantity.value = quantity;
                        }
                        return x;
                    })
                );
            }
        }
    };

    const cartNeedUpdate = () => {
        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.pvid === x.itemId);

                return item && item.count !== x.value && x.value !== "";
            }).length > 0
        );
    };

    const renderItems = () => {
        return cart.items.map((item) => {
            const image = (
                <div className="product-image">
                    <div className="product-image__body">
                        <img
                            className="product-image__img"
                            src={
                                item.image
                                    ? `${config.apiImageUrl}/small/${item.image}`
                                    : `${config.apiImageUrl}/small/images/no-image.png`
                            }
                            alt=""
                        />
                    </div>
                </div>
            );

            const removeButton = (
                <AsyncAction
                    action={() => cartRemoveItem(item.pvid)}
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });

                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <tr key={item.pvid} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">{image}</td>
                    <td className="cart-table__column cart-table__column--product">
                        <div className="cart-table__product-name">
                            {item.name}
                            {item.type ? (
                                <small>
                                    {" "}
                                    ({item.type}: {item.varname})
                                </small>
                            ) : (
                                ""
                            )}
                        </div>
                        <div>Availability: {item.available}</div>
                    </td>
                    <td className="cart-table__column cart-table__column--price" data-title="Price">
                        <Currency value={item.price} />
                    </td>
                    <td className="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <InputNumber
                            onChange={(quantity) => handleChangeQuantity(item, quantity)}
                            value={getItemQuantity(item)}
                            min={1}
                            max={item.available}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--total" data-title="Total">
                        <Currency value={item.amount} />
                    </td>
                    <td className="cart-table__column cart-table__column--remove">{removeButton}</td>
                </tr>
            );
        });
    };

    const renderTotals = () => {
        let extraLines;
        if (cart.tax) {
            extraLines = (
                <tr>
                    <th>{`Tax (${cart.tax}%)`}</th>
                    <td>
                        <Currency value={cart.total - cart.subtotal} />
                    </td>
                </tr>
            );
        }
        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th style={{ borderBottom: `${!cart.tax ? "1px solid #ebebeb" : ""}` }}>Subtotal</th>
                        <td style={{ borderBottom: `${!cart.tax ? "1px solid #ebebeb" : ""}` }}>
                            <Currency value={cart.subtotal} />
                        </td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">{extraLines}</tbody>
            </React.Fragment>
        );
    };

    const renderCart = () => {
        const updateCartButton = (
            <AsyncAction
                action={() => cartUpdateQuantities(quantities)}
                render={({ run, loading }) => {
                    const classes = classNames("btn btn-primary cart__update-button", {
                        "btn-loading": loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes} disabled={!cartNeedUpdate()}>
                            Update Cart
                        </button>
                    );
                }}
            />
        );
        const clearButton = (
            <AsyncAction
                action={() => clearCart()}
                render={({ run, loading }) => {
                    const classes = classNames("btn btn-danger", {
                        "btn-loading": loading,
                    });

                    return (
                        <button type="button" onClick={run} className={classes}>
                            Clear Cart
                        </button>
                    );
                }}
            />
        );
        let handleAllow;
        if (!allow) {
            handleAllow = (
                <div className="mb-3">
                    <div className="alert alert-danger alert-lg">
                        <strong>
                            Sorry, online payment is unavailable at the moment. Please try again later. Thanks
                        </strong>
                    </div>
                </div>
            );
        }
        return (
            <div className="cart block">
                <div className="container">
                    {handleAllow}

                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">Image</th>
                                <th className="cart-table__column cart-table__column--product">Product Name</th>
                                <th className="cart-table__column cart-table__column--price">Price</th>
                                <th className="cart-table__column cart-table__column--quantity">Quantity</th>
                                <th className="cart-table__column cart-table__column--total">Total</th>
                                <th className="cart-table__column cart-table__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{renderItems()}</tbody>
                    </table>
                    <div className="cart__actions">
                        <div className="cart__buttons2">{clearButton}</div>
                        <div className="cart__buttons">
                            <Link to="/" className="btn btn-light">
                                Continue Shopping
                            </Link>
                            {updateCartButton}
                        </div>
                    </div>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Cart Totals</h3>
                                    <table className="cart__totals">
                                        {renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>Total</th>
                                                <td>
                                                    <Currency value={cart.total} />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <div className="text-muted mb-3">Delivery fee not included yet</div>
                                    <button
                                        onClick={goToCheckout}
                                        className="btn btn-primary btn-xl btn-block cart__checkout-button"
                                        disabled={allow ? false : true}
                                    >
                                        Payment Checkout
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "Shopping Cart", url: "" },
    ];

    let content;

    if (taxerr && taxerr.length) {
        content = (
            <ErrorLoading
                title={"Shopping cart can not be shown!"}
                errMsg={taxerr}
                reloadNow={true}
                isLoading={isLoading}
            />
        );
    } else if (cart.quantity) {
        content = renderCart();
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">Your shopping cart is empty!</div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Shopping Cart — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="Shopping Cart" breadcrumb={breadcrumb} />

            {content}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    reload: state.app.reload,
    setHideTopAlert: (boolean) => setHideTopAlert(boolean),
});

const mapDispatchToProps = {
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
    cartRemoveItem,
    cartUpdateQuantities,
    clearCart: () => clearCart(),
    setTax: (tax) => setTax(tax),
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
