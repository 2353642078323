// react
import React, { useEffect } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

import {
    //  Redirect,
    Route,
    Switch,
    useHistory,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { connect } from "react-redux";

// application
import Footer from "./footer";
import Header from "./header";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";

// pages
import AccountLayout from "./account/AccountLayout";
import AccountPageLogin from "./account/AccountPageLogin";
import AccountPageRegister from "./account/AccountPageRegister";
import AccountForgetPassword from "./account/AccountForgetPassword";
import AccountResetOtp from "./account/AccountResetOtp";
import AccountResetPassword from "./account/AccountResetPassword";
import PageCart from "./shop/ShopPageCart";
import PageCheckout from "./shop/ShopPageCheckout";
import PageWishlist from "./shop/ShopPageWishlist";
import ShopPageOrderSuccess from "./shop/ShopPageOrderSuccess";
import ShopPageProduct from "./shop/ShopPageProduct";
import SitePageAboutUs from "./site/SitePageAboutUs";
import SitePageNotFound from "./site/SitePageNotFound";
import SitePageTerms from "./site/SitePageTerms";
import SitePagePrivacy from "./site/SitePagePrivacy";
import ShopProducts from "./shop/ShopProducts";
import EnterFactory from "./factory/EnterFactory";

// data stubs
import theme from "../data/theme";
import { getUsr, config, getCookie, setCookie, deleteCookie } from "../data/custom";
import { loadU_S_R } from "../store/app";
import { setCloseTopAlert } from "../store/menu";

const productsLayouts = [["/products/:slug/:prdsid", { columns: 3, viewMode: "grid", sidebarPosition: "start" }]].map(
    ([url, options]) => (
        <Route
            key={url}
            exact
            path={url}
            render={(props) => <ShopProducts {...props} {...options} categorySlug="power-tools" />}
        />
    )
);
const searchLayouts = [["/products/search", { columns: 4, viewMode: "grid", searchPage: true }]].map(
    ([url, options]) => (
        <Route
            key={url}
            exact
            path={url}
            render={(props) => <ShopProducts {...props} {...options} categorySlug="power-tools" />}
        />
    )
);

const productSlugLayouts = [["/product/:slug", { layout: "standard" }]].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => <ShopPageProduct {...props} {...options} productSlug="brandix-screwdriver-screw1500acc" />}
    />
));

function Layout(props) {
    const {
        match,
        headerLayout,
        homeComponent,
        customer,
        loadU_S_R,
        reload,
        cart,
        closeTopAlert,
        setCloseTopAlert,
        hideTopAlert,
    } = props;
    let history = useHistory();
    useEffect(() => {
        const cookies = getCookie("optc_token");
        const cookiesN = getCookie("optc_u_s_r");
        if (!customer && cookiesN) {
            fetch(`${config.apiUrl}/auth/me`, {
                method: "GET",
                crossDomain: true,
                headers: {
                    authorization: `Bearer ${JSON.parse(cookies)}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success === true) {
                        if (JSON.parse(cookiesN).name && JSON.parse(cookiesN).name !== data.data.name) {
                            const usr = getUsr(data.data);
                            setCookie("optc_u_s_r", usr);
                            loadU_S_R(usr);
                        }
                    } else {
                        deleteCookie("optc_u_s_r");
                        deleteCookie("optc_token");
                    }
                })
                .catch((error) => {
                    if (error.message === "Failed to fetch") {
                        //seterrMsg(["Please check your internet connection and reload the page"]);
                    } else {
                        //seterrMsg(["An error occured!"]);
                    }
                });
        }
    }, [reload, customer, loadU_S_R]);
    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            {/* <Quickview /> */}
            {cart.quantity && !closeTopAlert && !hideTopAlert ? (
                <div className="top-alert">
                    <div className="top-alert-holder">
                        <div style={{ height: "100%" }} className="container">
                            <div className="top-alert-inner">
                                {cart.quantity} items selected. <span className="txt-reveal">Click here to </span>
                                <button onClick={() => history.push("/checkout")} className="btn-top">
                                    Process Payment
                                </button>
                            </div>
                        </div>
                        <button onClick={() => setCloseTopAlert(true)} className="top-alert-close">
                            x
                        </button>
                    </div>
                </div>
            ) : (
                ""
            )}
            <MobileMenu />

            <div
                style={{ paddingTop: cart.quantity && !closeTopAlert && !hideTopAlert ? "40px" : "0px" }}
                className="site"
            >
                <header className="site__header d-lg-none">
                    <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={homeComponent} />

                        {/*
                        // Shop
                        */}
                        {productsLayouts}
                        {productSlugLayouts}
                        {searchLayouts}

                        <Route exact path="/cart" component={PageCart} />
                        <Route exact path="/checkout" component={PageCheckout} />
                        <Route exact path="/order/:referenceno" component={ShopPageOrderSuccess} />
                        <Route exact path="/wishlist" component={PageWishlist} />
                        <Route exact path="/forget-password" component={AccountForgetPassword} />
                        <Route exact path="/reset-otp/:auth" component={AccountResetOtp} />
                        <Route exact path="/reset-password/:auth" component={AccountResetPassword} />

                        {/*
                        // Account
                        */}
                        <Route exact path="/login" component={AccountPageLogin} />
                        <Route exact path="/register" component={AccountPageRegister} />
                        <Route path="/account" component={AccountLayout} />

                        {/*
                        // Site
                        */}
                        <Route exact path="/about-us" component={SitePageAboutUs} />
                        <Route exact path="/terms" component={SitePageTerms} />
                        <Route exact path="/privacy-policy" component={SitePagePrivacy} />

                        {/*
                        // Site
                        */}
                        <Route exact path="/factory" component={EnterFactory} />

                        {/*
                        // Page Not Found
                        */}
                        <Route component={SitePageNotFound} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: "default",
};

const mapStateToProps = (state) => ({
    customer: state.app.customer.name,
    reload: state.app.reload,
    cart: state.cart,
    closeTopAlert: state.menu.closeTopAlert,
    hideTopAlert: state.menu.hideTopAlert,
});

const mapDispatchToProps = {
    loadU_S_R: (customer) => loadU_S_R(customer),
    setCloseTopAlert: (boolean) => setCloseTopAlert(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
