// react
import React from "react";

import { connect } from "react-redux";

// application
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";
import FooterNewsletter from "./FooterNewsletter";
import ToTop from "./ToTop";

// data stubs
// import theme from "../../data/theme";

const Footer = ({ info, customer, year }) => {
    const informationLinks = [
        { title: "Home", url: "/" },
        { title: "About Us", url: "/about-us" },
        { title: "Privacy Policy", url: "/privacy-policy" },
        { title: "Terms And Conditions", url: "/terms" },
    ];
    let accountLinks = [
        { title: "Login", url: "/login" },
        { title: "Register", url: "/register" },
        { title: "Wishlist", url: "/wishlist" },
    ];
    if (customer) {
        accountLinks = [
            { title: "Dashboard", url: "/account/dashboard" },
            { title: "Edit Profile", url: "/account/profile" },
            { title: "Order History", url: "/account/orders" },
            { title: "Factory Request History", url: "/account/factory" },
            { title: "Change Password", url: "/account/change-password" },
            { title: "Wishlist", url: "/wishlist" },
        ];
    }

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts
                                webcontactintro={info.webcontactintro}
                                webcontactaddress={info.webcontactaddress}
                                webemail={info.webemail}
                                webphone={info.webphone}
                                webworkingtime={info.webworkingtime}
                            />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Information" items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="My Account" items={accountLinks} />
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter webnewsletterintro={info.webnewsletterintro} />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        &copy; {year} OPTRACOY. OLORUNWAPELUMI TRADING COMPANY
                        {/* Designed and Developed by{" "}
                        <a href={theme.author.profile_url} target="_blank" rel="noopener noreferrer">
                            {theme.author.name}
                        </a> */}
                    </div>
                    <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>
                </div>
            </div>
            <div className="whatapp d-block d-none d-sm-block d-md-none">
                <a
                    href="https://wa.link/p2k3mf"
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Whatapp"
                    className="btn btn-success rounded-whatapp"
                >
                    <i className="fab fa-whatsapp" aria-hidden="true"></i>
                </a>
            </div>
            <ToTop />
        </div>
    );
};
const mapStateToProps = (state) => ({
    info: state.menu.info,
    year: state.menu.year,
    customer: state.app.customer.name,
});
export default connect(mapStateToProps)(Footer);
