// react
import React, { useRef, useState, useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
// data stubs
import theme from "../../data/theme";
import { getUsr, config, getCookie, setCookie, deleteCookie, recapta_site_key } from "../../data/custom";
import ErrorLoading from "../errors/ErrorLoading";
import MessageAlert from "../errors/MessageAlert";
import { loadU_S_R, setPriceFilter, setBrandCheck } from "../../store/app";
import BlockLoader from "../blocks/BlockLoader";
import DeleteAccountModal from "./DeleteAccountModal";

const AccountPageProfile = ({ priceFilter, brandCheck, setPriceFilter, setBrandCheck, reload, loadU_S_R }) => {
    const recaptchaRef = useRef(null);
    const [user, setUser] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [load, setLoad] = useState(false);
    const [load2, setLoad2] = useState(false);
    const [show, setShow] = useState(false);
    const [errMsg, seterrMsg] = useState([]);
    const [alertMsg, setAlertMsg] = useState([]);
    const { handleSubmit, register, errors } = useForm();
    useEffect(() => {
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        seterrMsg([]);
        setAlertMsg([]);
        setIsLoading(true);
        const cookies = getCookie("optc_token");
        const cookiesN = getCookie("optc_u_s_r");
        fetch(`${config.apiUrl}/auth/me`, {
            method: "GET",
            crossDomain: true,
            headers: {
                authorization: `Bearer ${JSON.parse(cookies)}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    if (JSON.parse(cookiesN).name && JSON.parse(cookiesN).name !== data.data.name) {
                        const usr = getUsr(data.data);
                        setCookie("optc_u_s_r", usr);
                        loadU_S_R(usr);
                    }
                    setUser(data.data);
                } else {
                    seterrMsg(data.error);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["Please check your internet connection and reload the page"]);
                } else {
                    seterrMsg(["An error occured!"]);
                }
                setIsLoading(false);
            });
    }, [reload, priceFilter, brandCheck, setPriceFilter, setBrandCheck, loadU_S_R]);
    const onSubmit = async (values) => {
        setAlertMsg([]);
        setLoad(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setLoad(false);
            return toast.error("No internet connection found!");
        }
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/auth/updatedetails`, {
            method: "PUT",
            crossDomain: true,
            headers: {
                authorization: `Bearer ${JSON.parse(cookies)}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    const usr = getUsr(data.data);
                    setCookie("optc_u_s_r", usr);
                    loadU_S_R(usr);
                    setUser(data.data);
                    toast.success("Your profile has been updated successfully!");
                } else {
                    setAlertMsg(data.error);
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["Please check your internet connection and reload the page"]);
                } else {
                    seterrMsg(["An error occured!"]);
                }
                setLoad(false);
            });
    };

    const warningOnDeleteAccount = () => {
        setShow(true);
    };

    const deleteUserAccount = () => {
        setAlertMsg([]);
        setLoad2(true);
        const cookies = getCookie("optc_token");
        fetch(`${config.apiUrl}/users`, {
            method: "DELETE",
            crossDomain: true,
            headers: {
                authorization: `Bearer ${JSON.parse(cookies)}`,
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    deleteCookie("optc_u_s_r");
                    deleteCookie("optc_token");
                    loadU_S_R("");
                    toast.dark("Your account has been deleted successfullly!");
                } else {
                    setAlertMsg(data.error);
                }
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    seterrMsg(["Please check your internet connection and reload the page"]);
                } else {
                    seterrMsg(["An error occured!"]);
                }
            })
            .finally(() => {
                setShow(false);
                setLoad2(false);
            });
        console.log("delete user");
    };

    if (isLoading) {
        return <BlockLoader />;
    }
    let content;
    if (errMsg && errMsg.length) {
        content = (
            <ErrorLoading
                title={"We could not show your profile!"}
                errMsg={errMsg}
                reloadNow={true}
                isLoading={isLoading}
                removeBorder={true}
            />
        );
    } else {
        content = (
            <>
                <div className="card-header">
                    <h5>Edit Profile</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <div className="row no-gutters">
                        <div className="col-12 col-lg-7 col-xl-6">
                            {alertMsg.length ? (
                                <div className="">
                                    <MessageAlert setAlertMsg={setAlertMsg}>
                                        {alertMsg.map((alertM, index) => (
                                            <strong key={index}>{alertM}</strong>
                                        ))}{" "}
                                    </MessageAlert>
                                </div>
                            ) : (
                                ""
                            )}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        autoComplete="off"
                                        id="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        defaultValue={user.name}
                                        name="name"
                                        ref={register({
                                            required: "Please enter your name.",
                                            maxLength: 100,
                                        })}
                                    />
                                    <p className="text-danger">
                                        {errors.name && errors.name.message}
                                        {errors.name && errors.name.type === "maxLength" && (
                                            <span>Name can not be more than 100 characters.</span>
                                        )}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="number">Phone Number</label>
                                    <input
                                        autoComplete="off"
                                        id="number"
                                        type="text"
                                        className="form-control"
                                        placeholder="Phone Number"
                                        name="number"
                                        defaultValue={user.number}
                                        ref={register({
                                            required: "Please enter your phone number.",
                                            minLength: 4,
                                            maxLength: 20,
                                            pattern: {
                                                value: /^[0-9]*$/,
                                                message: "Invalid phone number.",
                                            },
                                        })}
                                    />
                                    <p className="text-danger">
                                        {errors.number && errors.number.message}
                                        {errors.number && errors.number.type === "minLength" && (
                                            <span>Phone number is too short.</span>
                                        )}
                                        {errors.number && errors.number.type === "maxLength" && (
                                            <span>Phone number is too long.</span>
                                        )}
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="email">Email Address</label>
                                    <input
                                        id="email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Email Address"
                                        defaultValue={user.email}
                                        name="email"
                                        ref={register({
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: "Invalid email address",
                                            },
                                        })}
                                    />
                                    <p className="text-danger">{errors.email && errors.email.message}</p>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="profile-phone">Address</label>
                                    <textarea
                                        id="profile-phone"
                                        type="text"
                                        className="form-control"
                                        placeholder="Address"
                                        defaultValue={user.address}
                                        name="address"
                                        ref={register({
                                            maxLength: 200,
                                        })}
                                    />
                                    <p className="text-danger">
                                        {errors.address && errors.address.type === "maxLength" && (
                                            <span>Address should not be more than 200 characters.</span>
                                        )}
                                    </p>
                                </div>

                                <div className="form-group mt-5 mb-0">
                                    <button
                                        type="submit"
                                        disabled={load ? true : false}
                                        className={classNames("btn btn-primary", {
                                            "btn-loading": load,
                                        })}
                                    >
                                        Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-header">
                    <h5>Delete Your Account</h5>
                </div>
                <div className="card-divider" />
                <div className="card-body">
                    <p>
                        By deleting your account, you'll no longer be able to access to all information about your
                        orders or log in.
                    </p>
                    <div className="mt-4 mb-0">
                        <button
                            type="button"
                            disabled={load2 ? true : false}
                            className={classNames("btn btn-danger", {
                                "btn-loading": load,
                            })}
                            onClick={warningOnDeleteAccount}
                        >
                            Delete Account
                        </button>
                    </div>
                </div>
                <DeleteAccountModal show={show} setShow={setShow} load2={load2} deleteUserAccount={deleteUserAccount} />
            </>
        );
    }
    return (
        <div className="card">
            <Helmet>
                <title>{`Profile — ${theme.name}`}</title>
            </Helmet>

            {content}
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
        </div>
    );
};

const mapStateToProps = (state) => ({
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    customer: state.app.customer.name,
});

const mapDispatchToProps = {
    loadU_S_R: (customer) => loadU_S_R(customer),
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageProfile);
