import { ADD_ROW, EDIT_ROW, REMOVE_ROW, CLEAR_ROWS, MATERIAL_COLOR, JOB_DESC } from "./factoryActionTypes";

export function addRowItem(row) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch({
                    type: ADD_ROW,
                    payload: row,
                });
                resolve();
            }, 500);
        });
}

export function editRowItem(row, rowNo) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch({
                    type: EDIT_ROW,
                    payload: { row, rowNo },
                });
                resolve();
            }, 500);
        });
}

export function removeRowItem(rowId) {
    // sending request to server, timeout is used as a stub
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch({
                    type: REMOVE_ROW,
                    payload: rowId,
                });
                resolve();
            }, 500);
        });
}
export function clearRows() {
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch({
                    type: CLEAR_ROWS,
                });
                resolve();
            }, 500);
        });
}
export function setMaterialColor(value = "") {
    return (dispatch) =>
        dispatch({
            type: MATERIAL_COLOR,
            payload: value,
        });
}
export function setJobDesc(value) {
    return (dispatch) =>
        dispatch({
            type: JOB_DESC,
            payload: value,
        });
}
