import { toast } from "react-toastify";
import { WISHLIST_ADD_ITEM, WISHLIST_REMOVE_ITEM, SET_WISHLIST } from "./wishlistActionTypes";

function addItem(state, product) {
    const itemIndex = state.findIndex((x) => x._id === product._id);

    if (itemIndex === -1) {
        toast.success(`Product "${product.productname}" added to wish list!`);
        localStorage.setItem("wishList", JSON.stringify([...state, JSON.parse(JSON.stringify(product))]));
        return [...state, JSON.parse(JSON.stringify(product))];
    }
    toast.error(`Product "${product.productname}" has already been added to wish list!`);
    return state;
}

const initialState = [];

export default function wishlistReducer(state = initialState, action) {
    switch (action.type) {
        case WISHLIST_ADD_ITEM:
            return addItem(state, action.product);

        case WISHLIST_REMOVE_ITEM:
            const newState = state.filter((x) => x._id !== action.productId);
            localStorage.setItem("wishList", JSON.stringify(newState));
            return newState;
        case SET_WISHLIST:
            return action.items;

        default:
            return state;
    }
}
