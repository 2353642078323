// react
import React, { useRef, useState, useEffect } from "react";

// third-party
import { toast } from "react-toastify";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import qs from "query-string";
import ReCAPTCHA from "react-google-recaptcha";

// application
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";
import { getUsr, config, setCookie, recapta_site_key } from "../../data/custom";
import MessageAlert from "../errors/MessageAlert";
import { loadU_S_R, setPriceFilter, setBrandCheck } from "../../store/app";

// data stubs
import theme from "../../data/theme";

function AccountPageLogin({ loadU_S_R, cart, priceFilter, brandCheck, setPriceFilter, setBrandCheck, customer }) {
    const breadcrumb = [
        { title: "Home", url: "" },
        { title: "My Account Login", url: "" },
    ];
    const recaptchaRef = useRef(null);
    const logRef = useRef(null);
    const { handleSubmit, register, errors, reset } = useForm();
    const [load, setLoad] = useState(false);
    const [alertMsg, setAlertMsg] = useState([]);
    const [back, setBack] = useState("");
    let history = useHistory();
    useEffect(() => {
        const to = qs.parse(window.location.search);
        priceFilter && setPriceFilter(false);
        brandCheck && setBrandCheck(false);
        if (to.return && to.return === "checkout") {
            if (cart.items.length < 1) {
                return history.push("login");
            }
            if (customer) {
                return history.push("checkout");
            }
            setBack(to.return);
        } else if (to.return && to.return === "factory") {
            if (customer) {
                return history.push("factory");
            }
            setBack(to.return);
        } else if (customer) {
            return history.push("");
        }
    }, [customer, brandCheck, history, priceFilter, setBrandCheck, setPriceFilter, cart.items.length]);
    const onSubmit = async (values) => {
        setLoad(true);
        try {
            const recaptchaValue = await recaptchaRef.current.executeAsync();
            recaptchaRef.current.reset();
            values.recaptchaValue = recaptchaValue;
        } catch (error) {
            setLoad(false);
            return toast.error("No internet connection found!");
        }
        fetch(`${config.apiUrl}/auth/login`, {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    const usr = getUsr(data.data);
                    let lref = null;
                    if (logRef.current.checked) {
                        lref = 30;
                    }
                    reset();
                    toast.success(`Hello ${data.data.name}. You are logged in${lref ? " for 30days" : ""}!`);
                    setCookie("optc_token", data.token, lref);
                    setCookie("optc_u_s_r", usr, lref);
                    loadU_S_R(usr);
                } else {
                    setAlertMsg(data.error);
                }
                setLoad(false);
            })
            .catch((error) => {
                if (error.message === "Failed to fetch") {
                    toast.error("No internet connection found!");
                } else {
                    toast.error("An error occured!");
                }
                setLoad(false);
            });
    };
    let handleReturnMsg = null;
    if (back === "checkout") {
        handleReturnMsg = (
            <div className="col-12 mb-3">
                <div className="alert alert-primary alert-lg">
                    <strong>
                        You have to login below first to proceed with your checkout if you are a registered customer or,{" "}
                        <Link to="/register?return=checkout">register here</Link> if you are a new customer
                    </strong>
                </div>
            </div>
        );
    }
    if (back === "factory") {
        handleReturnMsg = (
            <div className="col-12 mb-3">
                <div className="alert alert-primary alert-lg">
                    <strong>
                        You have to login below first to access factory request page if you are a registered customer
                        or, <Link to="/register?return=factory">register here</Link> if you are a new customer
                    </strong>
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>

            <PageHeader header="My Account" breadcrumb={breadcrumb} />
            <ReCAPTCHA sitekey={recapta_site_key} size="invisible" ref={recaptchaRef} />
            <div className="block">
                <div className="container">
                    <div className="row">
                        {handleReturnMsg}
                        <div className="col-md-6 d-flex">
                            <div className="card flex-grow-1 mb-md-0">
                                <div className="card-body">
                                    <h3 className="card-title text-center">Login</h3>
                                    {alertMsg.length ? (
                                        <div className="">
                                            <MessageAlert setAlertMsg={setAlertMsg}>
                                                {alertMsg.map((alertM, index) => (
                                                    <strong key={index}>{alertM}</strong>
                                                ))}{" "}
                                            </MessageAlert>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="form-group">
                                            <label htmlFor="login-phone">
                                                Phone Number<sup className="text-danger"> *</sup>
                                            </label>
                                            <input
                                                autoComplete="off"
                                                name="number"
                                                id="login-phone"
                                                type="text"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.number,
                                                })}
                                                placeholder="Phone number"
                                                ref={register({
                                                    required: "Please enter your phone number.",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Phone number is too short",
                                                    },
                                                    maxLength: {
                                                        value: 20,
                                                        message: "Phone number is too long",
                                                    },
                                                    pattern: {
                                                        value: /^[0-9]*$/,
                                                        message: "Invalid phone number.",
                                                    },
                                                })}
                                            />
                                            {errors.number && (
                                                <div className="invalid-feedback">{errors.number.message}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="login-password">
                                                Password<sup className="text-danger"> *</sup>
                                            </label>
                                            <input
                                                autoComplete="off"
                                                name="password"
                                                id="login-password"
                                                type="password"
                                                className={classNames("form-control", {
                                                    "is-invalid": errors.password,
                                                })}
                                                placeholder="Password"
                                                ref={register({
                                                    required: "Please enter your password",
                                                    minLength: {
                                                        value: 4,
                                                        message: "Your Password is not less than 4 characters",
                                                    },
                                                })}
                                            />
                                            {errors.password && (
                                                <div className="invalid-feedback">{errors.password.message}</div>
                                            )}
                                            <div className="form-text text-muted">
                                                <Link to="/forget-password">Forgot Password?</Link>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <span className="form-check-input input-check">
                                                    <span className="input-check__body">
                                                        <input
                                                            ref={logRef}
                                                            id="header-remember"
                                                            type="checkbox"
                                                            className="input-check__input"
                                                        />
                                                        <span className="input-check__box" />
                                                        <Check9x7Svg className="input-check__icon" />
                                                    </span>
                                                </span>
                                                <label className="form-check-label" htmlFor="header-remember">
                                                    Keep Me logged-in for 30 days
                                                </label>
                                            </div>
                                        </div>
                                        <button
                                            disabled={load ? true : false}
                                            type="submit"
                                            className={classNames("btn btn-block btn-primary mt-2 mt-md-3 mt-lg-4", {
                                                "btn-loading": load,
                                            })}
                                        >
                                            Login
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex mt-4 mt-md-0">
                            <div className="card flex-grow-1 mb-0">
                                <div className="card-body">
                                    <h3 className="card-title text-center">Register</h3>
                                    <p>
                                        Create your customer account in just a few clicks! You can register using your
                                        phone number or login. Click the button below.{" "}
                                    </p>
                                    <Link to={back ? `/register?return=${back}` : "/register"}>
                                        <button type="submit" className="btn btn-block btn-dark mt-2 mt-md-3 mt-lg-4">
                                            Register
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

AccountPageLogin.propTypes = {
    loadU_S_R: PropTypes.func,
};

const mapStateToProps = (state) => ({
    cart: state.cart,
    priceFilter: state.app.priceFilter,
    brandCheck: state.app.brandCheck,
    customer: state.app.customer.name,
});

const mapDispatchToProps = {
    loadU_S_R: (customer) => loadU_S_R(customer),
    setPriceFilter: (boolean) => setPriceFilter(boolean),
    setBrandCheck: (boolean) => setBrandCheck(boolean),
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPageLogin);
