import {
    LOAD_USR,
    PRICE_FILTER,
    BRAND_CHECK,
    SLIDER_PICTURES,
    FEATURED_PRODUCTS,
    SALES_PRODUCTS,
    BRAND_IMAGES,
    RELOAD,
    SEARCH,
} from "./appActionTypes";

/**
 * @param {string} customer
 */

const initialState = {
    customer: "",
    priceFilter: false,
    brandCheck: false,
    sliderPictures: [],
    featuredProducts: [],
    salesProducts: [],
    brandImages: [],
    reload: 0,
    search: "",
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_USR:
            return {
                ...state,
                customer: action.payload,
            };
        case PRICE_FILTER:
            return {
                ...state,
                priceFilter: action.payload,
            };
        case BRAND_CHECK:
            return {
                ...state,
                brandCheck: action.payload,
            };
        case SLIDER_PICTURES:
            return {
                ...state,
                sliderPictures: action.payload,
            };
        case FEATURED_PRODUCTS:
            return {
                ...state,
                featuredProducts: action.payload,
            };
        case SALES_PRODUCTS:
            return {
                ...state,
                salesProducts: action.payload,
            };
        case BRAND_IMAGES:
            return {
                ...state,
                brandImages: action.payload,
            };
        case RELOAD:
            return {
                ...state,
                reload: action.payload,
            };
        case SEARCH:
            return {
                ...state,
                search: action.payload,
            };

        default:
            return state;
    }
}
