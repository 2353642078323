import { toast } from "react-toastify";
import { ADD_ROW, EDIT_ROW, REMOVE_ROW, CLEAR_ROWS, LAST_ROW_ID, MATERIAL_COLOR, JOB_DESC } from "./factoryActionTypes";

function removeRow(Rows, rowId) {
    const newItems = Rows.filter((row) => row.id !== rowId);
    toast.dark("A row has been removed");
    return newItems;
}

const editRow = (Rows, editRes) => {
    const res = Rows.findIndex((row) => row.id === editRes.row.id);
    if (res !== -1) {
        Rows[res] = editRes.row;
        toast.success(`Row ${editRes.rowNo} has been edited`);
        return Rows;
    } else {
        return Rows;
    }
};
const addRow = (Rows, newRow) => {
    const requestRows = [...Rows, newRow];
    toast.success("A row has been added");
    return requestRows;
};

const initialState = {
    requestRows: [],
    jobDesc: "",
    materialColor: "",
};

export default function factoryReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_ROW:
            return { ...state, requestRows: addRow(state.requestRows, action.payload) };

        case EDIT_ROW:
            return { ...state, requestRows: editRow(state.requestRows, action.payload) };

        case REMOVE_ROW:
            return { ...state, requestRows: removeRow(state.requestRows, action.payload) };

        case CLEAR_ROWS:
            return { ...state, requestRows: [], lastRowId: 0 };

        case LAST_ROW_ID:
            return { ...state, lastRowId: action.payload };

        case MATERIAL_COLOR:
            return { ...state, materialColor: action.payload };
        case JOB_DESC:
            return { ...state, jobDesc: action.payload };
        default:
            return state;
    }
}
